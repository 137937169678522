<template>
  <div>
    <a-spin :spinning="isLoadingChild" tip="Calculating your results...">
      <div style="min-height: 300px;">
        <div v-if="survey && survey._id">
          <div class="flex items-center justify-between mt-8">
            <div class="flex space-x-4">
<!--              <a-radio-group v-model="analyticsView">-->
<!--                <a-radio-button value="summary">-->
<!--                  Summary-->
<!--                </a-radio-button>-->
<!--                <a-radio-button value="individual">-->
<!--                  Individual [{{responses_summary.total}}]-->
<!--                </a-radio-button>-->
<!--              </a-radio-group>-->
              <a-range-picker
                  :ranges="ranges"
                  :default-value="ranges['Last Month']"
                  @change="onChange"
              />
            </div>
            <div class="flex space-x-4 text-xl leading-none">
              <div class="font-light">
                Responses:
                <div
                    v-if="!isLoadingChild"
                    class="inline font-normal"
                >
                  {{ totalVisible }}
                </div>
                <a-icon v-else type="loading" style="font-size: 18px; color: var(--color-text-secondary)"></a-icon>
              </div>
              <div v-if="totalHidden > 0" class="mr-5 font-light text-magenta-500">
                Hidden:
                <div
                    v-if="!isLoadingChild"
                    class="inline font-normal"
                >
                  {{ totalHidden }}
                </div>
                <a-icon v-else type="loading" style="font-size: 18px; color: var(--color-text-secondary)"></a-icon>
              </div>
            </div>
          </div>
          <SurveyAnalyticQuestions/>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import SurveyAnalyticQuestions from "@/components/SurveyEdit/Analytic/SurveyAnalyticQuestions";
import {FETCH_RESPONSES} from "@/store/actions.type";
import {mapGetters} from "vuex";
import moment from "moment";
import {SET_RESPONSES_DATE_FILTER} from "../store/mutations.type";

export default {
  name: "SurveyEditAnalytic",
  components: {SurveyAnalyticQuestions},
  mounted() {
    this.$store.commit(`survey/${SET_RESPONSES_DATE_FILTER}`, this.ranges['Last Month']);
    this.$store.dispatch(`survey/${FETCH_RESPONSES}`, {survey_id: this.$route.params.id});
  },
  // data() {
  //   return {
  //     analyticsView: 'summary'
  //   }
  // },
  computed: {
    ...mapGetters('survey', ['survey', 'responses_summary', 'isLoadingChild']),
    ranges() {
      return {
        "Today": [moment().startOf('day'), moment().endOf('day')],
        "Last Week": [moment().subtract(7, 'days'), moment()],
        "Last Month": [moment().subtract(1, 'month'), moment()],
      }
    },
    totalVisible() {
      return this.responses_summary.total - this.responses_summary.hidden || 0;
    },
    totalHidden() {
      return this.responses_summary.hidden || 0;
    }
  },
  methods: {
    moment,
    onChange(dates) {
      this.$store.commit(`survey/${SET_RESPONSES_DATE_FILTER}`, dates);
      this.$store.dispatch(`survey/${FETCH_RESPONSES}`, {survey_id: this.$route.params.id});
    }
  }
}
</script>

<style scoped>

</style>
