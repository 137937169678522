<template>
  <VLimitReached>
    <VSurveyBlock
        v-for="(response, index) in resResponses"
        :key="index"
        id="survey-responses"
        class="mt-6"
    >
      <template #title>Question {{ index + 1 }}</template>
      <template #content>
        <div class="w-full flex items-stretch mt-3">
          <div
              class="flex items-center flex-none text-center border-0 border-r border-solid border-gray-200 pr-10 py-4">
            <div>
              <div class="leading-none mb-3 font-semibold text-lg">{{ response.typeName }}</div>
              <VQuestionIcon
                  :type="response.question.type"
                  :subtype="response.question.subtype"></VQuestionIcon>
            </div>
          </div>
          <div class="flex-grow pl-10 py-4">
            <div class="leading-none mb-10 font-light text-2xl">{{ response.question.text }}</div>
            <div v-if="response.question.type !== 'open'" class="flex">
              <div :class="response.gridClass">
                <div
                    v-for="(answer, index) in response.answers"
                    :key="index"
                    :class="response.answersMarginClass"
                >
                  <div class="flex items-center leading-none">
                    <div
                        v-if="response.question.type !== 'rating'"
                        class="inline mr-2 font-light text-lg"
                        :class="[answer.isHighlight ? answer.valueHighlightClass : answer.valueDefaultClass, answer.valueClass]"
                        :style="{'color': answer.isHighlight ? answer.barHighlightColor : ''}"
                    >
                      <span v-if="response.question.type === 'swipe'">
                        {{ response.question.swipeLabel[answer.value] }}
                      </span>
                      <span v-else-if="response.question.type !== 'multiple'">
                        {{ answer.value }}
                      </span>
                      <span v-else>{{ index + 1 }}</span>
                    </div>
                    <div
                        v-else
                        class="flex-none mr-4"
                        :class="[answer.isHighlight ? answer.valueHighlightClass : answer.valueDefaultClass, answer.valueClass]"
                    >
                      <a-rate v-if="response.question.subtype === 'stars'"
                              :value="+answer.value"
                              disabled
                              style="font-size: 30px;"
                      />
                      <VSmiles
                          v-else
                          :value="answer.value"
                          :type="response.question.subtype"
                          style="font-size: 36px"
                      />
                    </div>
                    <a-progress :percent="answer.rate"
                                :strokeWidth="12"
                                :strokeColor="answer.isHighlight ? answer.barHighlightColor : answer.barDefaultColor"
                                style="width: 430px;"
                    >
                      <template #format="percent">
                                            <span
                                                :class="{'font-semibold': answer.isHighlight, 'font-light': !answer.isHighlight}">
                                                <a-icon :type="answer.barIcon"
                                                        theme="filled"
                                                        :style="`color: ${answer.isHighlight ? answer.barHighlightColor : answer.barDefaultColor}`"
                                                        class="mx-1"/>
                                                {{ percent + "%" }}
                                            </span>
                      </template>
                    </a-progress>
                  </div>
                  <div
                      v-if="answer.choiceText"
                      class="w-3/4 pl-10 font-light text-lg">
                    {{ answer.choiceText }}
                  </div>
                </div>
              </div>
              <div v-if="response.question.type === 'swipe'" class="flex flex-grow justify-end -mt-12">
                <VQuestionSwipeImg
                    :subtype="response.question.subtype"
                    :img-src="response.question.imageSrc"/>
              </div>
            </div>
            <div v-else-if="response.question.type === 'open'"
                 class="mb-8 overflow-y-auto"
                 style="min-height: 100px; max-height: 400px;">
              <a-list item-layout="horizontal" :data-source="response.answers">
                <a-list-item slot="renderItem" slot-scope="answer">
                  <div class="flex w-full items-start">
                    <div class="w-1/5 font-light text-character_gray-200">
                      {{ answer.createdAt | moment('DD.MM.YYYY') }}
                    </div>
                    <div class="w-4/5">{{ answer.value }}</div>
                  </div>
                </a-list-item>
                <!--                <infinite-loading v-if="response.question.type === 'open' && response.answers.length >= 50"-->
                <!--                                  @infinite="infiniteOpenHandler(response.question._id, response.answers.length)"-->
                <!--                                  :ref="`InfiniteLoadingOpen${response.question._id}`"-->
                <!--                ></infinite-loading>-->
              </a-list>
            </div>

            <div
                class="w-full pl-3 space-x-8"
                :class="{'mt-4': response.question.type !== 'swipe'}"
            >
              <span class="font-light text-lg">{{ response.responses_cnt }} Answered</span>
              <span v-if="response.responses_hidden > 0"
                    class="font-light text-lg text-magenta-500">{{ response.responses_hidden }} Hidden</span>
              <!--              <span class="font-light text-lg">{{ response.completion_rate }}% Completed</span>-->
            </div>
          </div>
        </div>
      </template>
    </VSurveyBlock>

    <infinite-loading
        v-if="responses && responses.length > 0"
        @infinite="infiniteHandler"
        ref="InfiniteLoading"
    ></infinite-loading>
  </VLimitReached>
</template>

<script>
import VQuestionSwipeImg from "@/components/SurveyEdit/Analytic/VQuestionSwipeImg";
import VSurveyBlock from "@/components/VSurveyBlock";
import VQuestionIcon from "@/components/VQuestionIcon";
import VSmiles from "@/components/VSmiles";
import _ from 'lodash';
import {mapGetters} from "vuex";
import {FETCH_RESPONSES, FETCH_OPEN_RESPONSES} from "@/store/actions.type";
import VLimitReached from "../../VLimitReached";

export default {
  name: "SurveyAnalyticQuestions",
  components: {VSurveyBlock, VQuestionSwipeImg, VSmiles, VQuestionIcon, VLimitReached},
  mounted() {
    // this.$root.$on('reloadResults', () => {
    //   const requireReload = _.pickBy(this.$refs, (value, key) => _.startsWith(key, 'InfiniteLoadingOpen'));
    //   _.forEach(requireReload, (i) => {
    //     i[0]['stateChanger'].reset();
    //   })
    // });
  },
  data() {
    return {
      offset: 0,
      questionTypeParams: {
        swipe: {
          typeName: 'Swipe reply',
          gridClass: 'flex flex-col items-end flex-none',
          answersMarginClass: 'mb-6',
          valueClass: 'flex-none text-right',
          valueDefaultClass: '',
          valueHighlightClass: 'text-2xl font-semibold',
          barIcon: null,
          barDefaultColor: null,
          barHighlightColor: null,
          subtypes: {
            textWithImage: {},
            textOnly: {},
          },
          answerTypes: {
            yes: {
              barIcon: 'check-circle',
              barDefaultColor: 'var(--color-positive)',
              barHighlightColor: 'var(--color-positive)',
            },
            no: {
              barIcon: 'close-circle',
              barDefaultColor: 'var(--color-magenta-700)',
              barHighlightColor: 'var(--color-magenta-700)',
            },
          }
        },
        nps: {
          typeName: 'NPS',
          gridClass: null,
          answersMarginClass: 'mb-4 w-1/2',
          valueClass: 'nps-size-value flex flex-none items-center justify-center rounded-full mr-3',
          valueDefaultClass: 'bg-gray-100',
          valueHighlightClass: 'bg-blue-100 transform scale-125 font-semibold',
          barIcon: 'check-circle',
          barDefaultColor: 'var(--color-gray-500)',
          barHighlightColor: 'var(--color-blue-500)',
          subtypes: {
            horizontal: {
              gridClass: 'w-full',
            },
            vertical: {
              gridClass: 'w-full two-column-nps-block flex flex-col flex-wrap',
            }
          },
          answerTypes: {}
        },
        multiple: {
          typeName: 'Multiple answer',
          gridClass: 'w-full two-column-multiple-block flex flex-wrap',
          answersMarginClass: 'mb-6',
          valueClass: 'w-8 text-right',
          valueDefaultClass: '',
          valueHighlightClass: 'transform scale-125 font-semibold',
          barIcon: 'check-circle',
          barDefaultColor: 'var(--color-gray-500)',
          barHighlightColor: 'var(--color-blue-500)',
          subtypes: {},
          answerTypes: {}
        },
        rating: {
          typeName: 'Rating',
          gridClass: 'w-1/2',
          answersMarginClass: 'mb-4',
          valueClass: '',
          valueDefaultClass: null,
          valueHighlightClass: null,
          barIcon: 'check-circle',
          barDefaultColor: null,
          barHighlightColor: null,
          subtypes: {
            smilesColorful: {
              valueDefaultClass: 'opacity-50',
              valueHighlightClass: 'transform scale-125 opacity-100',
              barDefaultColor: {
                1: 'var(--color-magenta-700)',
                2: 'var(--color-orange-500)',
                3: 'var(--color-warning-500)',
                4: '#90AE39',
                5: 'var(--color-positive)',
              },
              barHighlightColor: {
                1: 'var(--color-magenta-700)',
                2: 'var(--color-orange-500)',
                3: 'var(--color-warning-500)',
                4: '#90AE39',
                5: 'var(--color-positive)',
              }
            },
            smilesGray: {
              valueDefaultClass: '',
              valueHighlightClass: 'transform scale-125',
              barDefaultColor: 'var(--color-gray-500)',
              barHighlightColor: 'var(--color-blue-500)',
            },
            stars: {
              valueDefaultClass: 'opacity-50',
              valueHighlightClass: 'opacity-100',
              barDefaultColor: 'var(--color-gray-500)',
              barHighlightColor: '#FAAD14',
            }
          }
        },
      },
    }
  },
  filters: {
    capitalize(value) {
      return _.capitalize(value);
    }
  },
  updated() {
    if (this.$refs.InfiniteLoading && this.offset >= this.resResponses.length + 10) {

      this.offset = 0;
      this.$refs.InfiniteLoading.stateChanger.reset();
    }
  },
  computed: {
    ...mapGetters('survey', ['survey', 'responses']),
    resResponses: function () {
      const questionTypeParams = this.questionTypeParams;

      const surveyTranslationsRight = this.survey.translations.questions.swipe.rightButton;
      const surveyTranslationsLeft = this.survey.translations.questions.swipe.leftButton;

      return _.map(this.responses, function (item) {
        let type = item.question.type,
            subtype = item.question.subtype;

        let questionTranslationsRight = item.question.translations.rightButton,
            questionTranslationsLeft = item.question.translations.leftButton;

        //Additional props
        let newItemProps = _.mapKeys(questionTypeParams[type], function (value, key) {
          let excludeResponseProps = ['subtypes', 'barIcon', 'barDefaultColor', 'barHighlightColor', 'answerTypes', 'valueClass', 'valueDefaultClass', 'valueHighlightClass']
          let excludeAnswerProps = ['typeName', 'gridClass', 'answersMarginClass', 'subtypes', 'answerTypes']
          if (excludeResponseProps.indexOf(key) === -1) {
            //Set additional props for the particular response
            if (value === null) {
              value = questionTypeParams[type].subtypes[subtype][key];
            }
            item[key] = value;
          } else if (excludeAnswerProps.indexOf(key) === -1) {
            //Set additional props for the particular answer
            item.answers = _.map(item.answers, function (answer) {
              if (value === null) {
                if (questionTypeParams[type].subtypes[subtype][key] !== undefined) {
                  let subtypeVal = questionTypeParams[type].subtypes[subtype][key];
                  if (typeof subtypeVal !== 'object') {
                    answer[key] = subtypeVal;
                  } else {
                    answer[key] = subtypeVal[answer.value];
                  }
                } else {
                  answer[key] = questionTypeParams[type].answerTypes[answer.value][key];
                }
              } else {
                answer[key] = value;
              }

              answer.isHighlight = false;
              let maxElement = _.maxBy(item.answers, 'rate');
              if (answer === maxElement && maxElement.rate !== 0) {
                answer.isHighlight = true;
              }

              return answer;
            });
          }

          return item;
        });

        if (type === "multiple") {
          item.answers.map((answer) => {
            answer.choiceText = item.question.choices.find(choice => choice._id === answer.value).text;
          })
        }

        if (type === 'swipe') {
          item.question.swipeLabel = {
            yes: questionTranslationsRight.value || surveyTranslationsRight.value || surveyTranslationsRight.defaultValue,
            no: questionTranslationsLeft.value || surveyTranslationsLeft.value || surveyTranslationsLeft.defaultValue,
          };
        }

        item = {...newItemProps, ...item};

        return item;
      })
    }
  },
  methods: {
    infiniteHandler($state) {
      this.offset += 10;
      this.$store.dispatch(`survey/${FETCH_RESPONSES}`, {
        survey_id: this.$route.params.id,
        offset: this.offset
      })
          .then(function () {
            $state.loaded()
          })
          .catch(function () {
            $state.complete()
          })
    },

    infiniteOpenHandler(question_id, offset) {
      const $state = this.$refs[`InfiniteLoadingOpen${question_id}`][0]['stateChanger']

      this.$store.dispatch(`survey/${FETCH_OPEN_RESPONSES}`, {
        survey_id: this.$route.params.id,
        question_id,
        offset,
      })
          .then(function () {
            $state.loaded()
          })
          .catch(function () {
            $state.complete()
          })
    }
  }
}
</script>

<style lang="less" scoped>
.two-column-nps-block {
  height: 315px;

  & > div:nth-child(n+7) {
    margin-left: 40px;
  }
}

.two-column-multiple-block {
  & > div {
    width: 50%;
  }
}

@v-nps-size-value: 36px;
.nps-size-value {
  height: @v-nps-size-value;
  width: @v-nps-size-value;
}
</style>
