import ApiService from "@/common/api.service";
import {
  FETCH_SURVEY,
  SURVEY_CREATE,
  SURVEY_CLONE,
  SURVEY_DELETE,
  SURVEY_EDIT,
  FETCH_QUESTIONS,
  QUESTION_CREATE,
  QUESTION_EDIT,
  QUESTION_DELETE,
  QUESTIONS_UPDATE_POSITION,
  FETCH_RESPONSES,
  FETCH_COMMENTS,
  CHOICE_CREATE,
  CHOICE_UPDATE,
  CHOICE_DELETE,
  CHOICES_UPDATE_POSITION,
  EXPORT_RESULTS,
  IMPORT_QUESTIONS_FROM_CSV,
  IMPORT_QUESTIONS_IMAGES,
  VERIFY_DOMAIN,
  DELETE_DOMAIN,
  FETCH_OPEN_RESPONSES,
  VERIFY_WEBHOOK,
  FETCH_SCREENS,
  CREATE_SCREEN,
  UPDATE_SCREEN,
  DELETE_SCREEN,
  DUPLICATE_SCREEN,
  RESET_RESULTS,
  GET_GOOGLE_CONNECT_URL,
  CONNECT_WITH_GOOGLE,
  CONNECTION_DISCONNECT,
  CALCULATED_FIELD_CREATE,
  QUESTION_DUPLICATE,
} from "./actions.type";
import {
  FETCH_START,
  FETCH_START_CHILD,
  SET_SURVEY,
  SET_QUESTIONS,
  SAVE_START,
  SAVE_END,
  SET_RESPONSES,
  SET_COMMENTS,
  DUPLICATE_START,
  DUPLICATE_END,
  ADD_CHOICE,
  REMOVE_CHOICE,
  RESET_STATE,
  FETCH_START_RESULTS,
  FETCH_FINISH_RESULTS,
  SET_OPEN_RESPONSES,
  SET_LIMIT_REACHED_ALERT,
  ADD_SCREEN,
  SET_SCREENS,
  REMOVE_SCREEN,
  RESET_RESPONSES_AND_COMMENTS,
  SET_GOOGLE_CONNECTION,
  DELETE_SURVEY_MUTATION,
  SET_TIMESTAMP_FOR_SURVEY,
  SET_TIMESTAMP_FOR_QUESTION,
  SET_TIMESTAMP_FOR_SCREEN,
  SET_TIMESTAMP_FOR_CHOICE,
  SET_SURVEY_CUSTOM_DOMAIN,
  REMOVE_CUSTOM_DOMAIN,
  ADD_CALCULATED_FIELD,
  ADD_QUESTION,
  REMOVE_QUESTION,
  REORDER_QUESTIONS,
  SET_RESPONSES_DATE_FILTER,
} from "./mutations.type";


const getDefaultState = () => {
  return {
    survey: {},
    welcomeScreen: null,
    thankyouScreens: [],
    questions: [],
    questions_count: 0,
    questions_show_count: 0,
    responses: [],
    responses_summary: {},
    responsesDateFilter: {
      startDate: null,
      endDate: null,
    },
    comments: [],
    comments_count: 0,
    isLoading: false,
    isLoadingChild: false,
    isSaving: false,
    isDuplicating: false,
    isFetchingResults: false,
    isLimitReachedAlert: false,
    // isQuestionCreating: false,
    timestamps: {
      survey: null,
      questions: {},
      screens: {},
      choices: {},
    },
  }
}

const state = getDefaultState();

const getters = {
  survey(state) {
    return state.survey;
  },
  welcomeScreen(state) {
    return state.welcomeScreen;
  },
  thankyouScreens(state) {
    return state.thankyouScreens;
  },
  questions(state) {
    return state.questions;
  },
  questions_count(state) {
    return state.questions.length;
  },
  questions_show_count(state) {
    return state.questions.length;
  },
  responses(state) {
    return state.responses;
  },
  responses_summary(state) {
    return state.responses_summary;
  },
  comments(state) {
    return state.comments;
  },
  comments_count(state) {
    return state.comments_count;
  },
  isLoading(state) {
    return state.isLoading;
  },
  isLoadingChild(state) {
    return state.isLoadingChild;
  },
  isSaving(state) {
    return state.isSaving;
  },
  isDuplicating(state) {
    return state.isDuplicating;
  },
  isFetchingResults(state) {
    return state.isFetchingResults;
  },
  isLimitReachedAlert(state) {
    return state.isLimitReachedAlert;
  }
}

const actions = {
  [FETCH_SURVEY]({commit}, id) {
    commit(FETCH_START);
    return new Promise((resolve, reject) => {
      ApiService.get(`${process.env.VUE_APP_API_URL}/admin/survey/${id}`)
        .then(response => {
          commit(RESET_STATE);
          commit(SET_SURVEY, response);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [SURVEY_CREATE](context, {folderId}) {
    return new Promise((resolve, reject) => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/admin/survey`, {folderId})
        .then(response => {
          if (response.type && response.type.toLowerCase() === 'error') {
            throw response.message;
          }
          const {_id} = response;
          resolve({_id});
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [SURVEY_CLONE](context, {survey_id, folderId}) {
    context.commit(DUPLICATE_START);
    return new Promise((resolve, reject) => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/admin/survey/${survey_id}/duplicate`, {folderId})
        .then(async (response) => {
          const {_id} = response;
          resolve({_id});
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          context.commit(DUPLICATE_END);
        })
    });
  },
  [SURVEY_EDIT]({commit, state}, {survey_id, options}) {
    commit(SAVE_START);

    let timestamp;
    if (state.survey._id === survey_id) {
      timestamp = state.timestamps.survey || state.survey.createdAt;
    } else {
      // to prevent overwriting when editing on the Dashboard
      timestamp = options.updatedAt || options.createdAt;
    }

    return new Promise((resolve, reject) => {
      ApiService.update(`${process.env.VUE_APP_API_URL}/admin/survey/${survey_id}`, {
        ...options,
        timestamp,
      })
        .then((survey) => {
          commit(SET_TIMESTAMP_FOR_SURVEY, {updatedAt: survey.updatedAt});
          commit(SAVE_END);
          resolve();
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [SURVEY_DELETE](context, {survey_id}) {
    return new Promise((resolve, reject) => {
      ApiService.destroy(`${process.env.VUE_APP_API_URL}/admin/survey/${survey_id}`)
        .then(() => {
          context.commit(`home/${DELETE_SURVEY_MUTATION}`, survey_id, {root: true});
          resolve();
        })
        .catch(error => {
          reject(error);
        })
    });
  },
  [FETCH_QUESTIONS]({commit}, {survey_id}) {
    commit(FETCH_START_CHILD);
    return new Promise(resolve => {
      ApiService.get(`${process.env.VUE_APP_API_URL}/admin/survey/${survey_id}/questions`)
        .then(response => {
          commit(SET_QUESTIONS, response);
          resolve(response);
        })
    });
  },
  [QUESTION_CREATE]({commit}, {survey_id, payload}) {
    commit(SAVE_START);
    return new Promise((resolve, reject) => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/admin/survey/${survey_id}/question`, payload)
        .then(response => {
          commit(ADD_QUESTION, response);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit(SAVE_END);
        })
    });
  },
  [QUESTION_DUPLICATE]({commit}, {survey_id, question_id}) {
    return new Promise((resolve, reject) => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/admin/survey/${survey_id}/question/${question_id}/duplicate`)
        .then(response => {
          commit(ADD_QUESTION, response);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [QUESTION_EDIT](context, {survey_id, payload}) {
    const timestamp = context.state.timestamps.questions[payload._id] || context.state.questions.find(q => q._id === payload._id)?.createdAt;
    context.commit(SAVE_START);
    return new Promise(resolve => {
      const {_id} = payload;
      ApiService.update(`${process.env.VUE_APP_API_URL}/admin/survey/${survey_id}/question/${_id}`, {
        ...payload,
        timestamp
      })
        .then((question) => {
          context.commit(SET_TIMESTAMP_FOR_QUESTION, {question_id: question._id, updatedAt: question.updatedAt});
          context.commit(SAVE_END);
          resolve();
        })
    });
  },
  [QUESTION_DELETE]({commit}, {survey_id, questionId}) {
    commit(SAVE_START);
    return new Promise((resolve, reject) => {
      ApiService.destroy(`${process.env.VUE_APP_API_URL}/admin/survey/${survey_id}/question/${questionId}`)
        .then(() => {
          commit(REMOVE_QUESTION, questionId);
          resolve();
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit(SAVE_END);
        });
    });
  },
  [QUESTIONS_UPDATE_POSITION]({commit}, {survey_id, questions}) {
    commit(SAVE_START);
    return new Promise(resolve => {
      ApiService.update(`${process.env.VUE_APP_API_URL}/admin/survey/${survey_id}/questions`, {questions})
        .then(() => {
          commit(REORDER_QUESTIONS, questions);
          resolve();
        })
        .finally(() => {
          commit(SAVE_END);
        });
    });
  },
  [FETCH_RESPONSES]({state, commit}, {survey_id, offset}) {
    commit(FETCH_START_CHILD);
    let query_string = '';

    const {startDate, endDate} = state.responsesDateFilter;
    if (startDate && endDate) {
      const formattedStartDate = startDate.toISOString();
      const formattedEndDate = endDate.toISOString();
      query_string += `?startDate=${formattedStartDate}&endDate=${formattedEndDate}`;
    }

    if (offset !== undefined) {
      query_string += `${query_string ? '&' : '?'}offset=${offset}`;
    }
    return new Promise((resolve, reject) => {
      ApiService.get(`${process.env.VUE_APP_API_URL}/admin/survey/${survey_id}/responses${query_string}`)
        .then(responses => {
          commit(SET_RESPONSES, {
            responses,
            query_string
          });
          if (responses.results.length <= 0) throw "All data was loaded!";
          resolve(responses);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [FETCH_OPEN_RESPONSES]({commit}, {survey_id, question_id, offset}) {
    let query_string = '';
    if (offset !== undefined) {
      query_string = `/?offset=${offset}`;
    }
    return new Promise((resolve, reject) => {
      ApiService.get(`${process.env.VUE_APP_API_URL}/admin/survey/${survey_id}/responses/${question_id}${query_string}`)
        .then(responses => {
          if (responses.length <= 0) throw "All data was loaded!";
          commit(SET_OPEN_RESPONSES, {question_id, responses});
          resolve(responses);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [FETCH_COMMENTS]({commit}, {survey_id, limit, offset, sort}) {
    commit(FETCH_START_CHILD);
    let query_string = '';
    if (limit !== undefined && offset !== undefined) {
      query_string = `/?limit=${limit}&offset=${offset}`;
    }
    if (sort !== undefined) {
      query_string += `&sort=${sort}`;
    }
    return new Promise((resolve, reject) => {
      ApiService.get(`${process.env.VUE_APP_API_URL}/admin/survey/${survey_id}/comments${query_string}`)
        .then(response => {
          commit(SET_COMMENTS, response);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [CHOICE_CREATE]({commit}, {survey_id, question_id, position}) {
    return new Promise((resolve, reject) => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/admin/survey/${survey_id}/question/${question_id}/choice`, {position})
        .then((choice) => {
          commit(ADD_CHOICE, {question_id, choice});
          resolve(choice);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [CHOICE_UPDATE](context, {survey_id, question_id, choice_id, text}) {
    const timestamp = context.state.timestamps.choices[choice_id] || context.state.questions.find(q => q._id === question_id)?.choices?.find(c => c._id === choice_id)?.createdAt;
    return new Promise((resolve, reject) => {
      ApiService.update(`${process.env.VUE_APP_API_URL}/admin/survey/${survey_id}/question/${question_id}/choice/${choice_id}`, {
        text,
        timestamp
      })
        .then((choice) => {
          context.commit(SET_TIMESTAMP_FOR_CHOICE, {choice_id: choice._id, updatedAt: choice.updatedAt});
          resolve(choice);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [CHOICE_DELETE]({commit}, {survey_id, question_id, choice_id}) {
    return new Promise((resolve, reject) => {
      ApiService.destroy(`${process.env.VUE_APP_API_URL}/admin/survey/${survey_id}/question/${question_id}/choice/${choice_id}`)
        .then((choice) => {
          commit(REMOVE_CHOICE, {question_id, choice_id});
          resolve(choice);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [CHOICES_UPDATE_POSITION](context, {survey_id, question_id, choices}) {
    return new Promise((resolve, reject) => {
      ApiService.update(`${process.env.VUE_APP_API_URL}/admin/survey/${survey_id}/question/${question_id}/choices`, {choices})
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [EXPORT_RESULTS]({commit}, {survey_id}) {
    commit(FETCH_START_RESULTS);
    return new Promise((resolve, reject) => {
      ApiService.get(`${process.env.VUE_APP_API_URL}/admin/survey/${survey_id}/export_results`)
        .then(response => {
          if (response.type && response.type.toLowerCase() === 'error') {
            throw response.message;
          }
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit(FETCH_FINISH_RESULTS);
        })
    });
  },
  [IMPORT_QUESTIONS_FROM_CSV](context, {survey_id, questions}) {
    context.commit(SAVE_START);
    return new Promise((resolve, reject) => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/admin/survey/${survey_id}/questions_import_from_csv`, {
        survey_id,
        questions
      })
        .then(response => {
          if (response.type && response.type.toLowerCase() === 'error') {
            throw response.message;
          }
          return response;
        })
        .then(response => {
          context.dispatch(FETCH_QUESTIONS, {survey_id});
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          context.commit(SAVE_END);
        })
    });
  },
  [IMPORT_QUESTIONS_IMAGES](context, formData) {
    const survey_id = formData.get('survey_id');
    context.commit(SAVE_START);
    return new Promise((resolve, reject) => {
      ApiService.filePost(`${process.env.VUE_APP_API_URL}/admin/upload/import_multi`, formData)
        .then(response => {
          if (response.type && response.type.toLowerCase() === 'error') {
            throw response.message;
          }
          return response;
        })
        .then(response => {
          context.dispatch(FETCH_QUESTIONS, {survey_id});
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          context.commit(SAVE_END);
        })
    });
  },
  [VERIFY_DOMAIN]({commit}, {survey_id, domain}) {
    commit(SAVE_START);
    return new Promise((resolve, reject) => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/admin/survey/${survey_id}/verify_domain`, {domain})
        .then(response => {
          if (response.type && response.type.toLowerCase() === 'error') {
            throw response.message;
          }
          return response;
        })
        .then(survey => {
          commit(SET_SURVEY_CUSTOM_DOMAIN, survey);
          resolve(survey);
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit(SAVE_END);
        })
    });
  },
  [DELETE_DOMAIN]({commit}, {survey_id, domain}) {
    commit(SAVE_START);
    return new Promise((resolve, reject) => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/admin/survey/${survey_id}/delete_domain`, {domain})
        .then(response => {
          if (response.type && response.type.toLowerCase() === 'error') {
            throw response.message;
          }
          return response;
        })
        .then(() => {
          commit(REMOVE_CUSTOM_DOMAIN, domain);
          resolve();
        })
        .catch(error => {
          reject(error);
        })
        .finally(() => {
          commit(SAVE_END);
        })
    });
  },
  [VERIFY_WEBHOOK](context, {survey_id, endpoint}) {
    return new Promise((resolve, reject) => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/admin/survey/${survey_id}/verify_webhook`, {endpoint})
        .then(response => {
          return response;
        })
        .then(response => {
          resolve(response);
        })
        .catch(error => {
          reject(error);
        })
    });
  },
  [FETCH_SCREENS]({commit}, {survey_id}) {
    return new Promise(resolve => {
      ApiService.get(`${process.env.VUE_APP_API_URL}/admin/survey/${survey_id}/screens`)
        .then(response => {
          commit(SET_SCREENS, response);
          resolve(response);
        })
    });
  },
  [CREATE_SCREEN]({commit}, {survey_id, type}) {
    return new Promise(resolve => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/admin/survey/${survey_id}/screen`, {type})
        .then(response => {
          commit(ADD_SCREEN, response);
          resolve(response);
        })
    });
  },
  [UPDATE_SCREEN](context, {survey_id, data}) {
    const timestamp = context.state.timestamps.screens[data._id] || context.state.thankyouScreens.find(s => s._id === data._id)?.createdAt || context.state.welcomeScreen?.createdAt;
    return new Promise(resolve => {
      ApiService.update(`${process.env.VUE_APP_API_URL}/admin/survey/${survey_id}/screen/${data._id}`, {
        data,
        timestamp
      })
        .then((screen) => {
          context.commit(SET_TIMESTAMP_FOR_SCREEN, {screen_id: screen._id, updatedAt: screen.updatedAt});
          resolve(screen);
        })
    });
  },
  [DELETE_SCREEN]({commit}, {survey_id, screenId}) {
    return new Promise(resolve => {
      ApiService.destroy(`${process.env.VUE_APP_API_URL}/admin/survey/${survey_id}/screen/${screenId}`)
        .then(response => {
          commit(REMOVE_SCREEN, screenId);
          resolve(response);
        })
    });
  },
  [DUPLICATE_SCREEN]({commit}, {survey_id, screenId}) {
    return new Promise(resolve => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/admin/survey/${survey_id}/screen/${screenId}/duplicate`)
        .then(response => {
          commit(ADD_SCREEN, response);
          resolve(response);
        })
    });
  },
  [RESET_RESULTS]({commit}, {survey_id}) {
    return new Promise(resolve => {
      ApiService.destroy(`${process.env.VUE_APP_API_URL}/admin/survey/${survey_id}/responses`)
        .then(response => {
          commit(RESET_RESPONSES_AND_COMMENTS);
          resolve(response);
        })
    });
  },
  [GET_GOOGLE_CONNECT_URL](context, {survey_id}) {
    return new Promise(resolve => {
      ApiService.get(`${process.env.VUE_APP_API_URL}/admin/survey/${survey_id}/connect/google`)
        .then(response => {
          resolve(response);
        })
    });
  },
  [CONNECT_WITH_GOOGLE]({commit}, {code, survey_id, isSendExisting}) {
    return new Promise((resolve, reject) => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/admin/survey/${survey_id}/connect/google`, {code, isSendExisting})
        .then(response => {
          commit(SET_GOOGLE_CONNECTION, response);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  [CONNECTION_DISCONNECT](context, {survey_id, app}) {
    return new Promise(resolve => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/admin/survey/${survey_id}/disconnect`, {app})
        .then(response => {
          resolve(response);
        })
    });
  },
  [CALCULATED_FIELD_CREATE]({commit}, {survey_id}) {
    return new Promise(resolve => {
      ApiService.post(`${process.env.VUE_APP_API_URL}/admin/survey/${survey_id}/calculated_field`)
        .then(calculatedField => {
          commit(ADD_CALCULATED_FIELD, {survey_id, calculatedField});
          resolve(calculatedField);
        })
    });
  },
}

const mutations = {
  [FETCH_START](state) {
    state.isLoading = true;
  },
  [FETCH_START_CHILD](state) {
    state.isLoadingChild = true;
  },
  [SAVE_START](state) {
    state.isSaving = true;
  },
  [SAVE_END](state) {
    state.isSaving = false;
  },
  [DUPLICATE_START](state) {
    state.isDuplicating = true;
  },
  [DUPLICATE_END](state) {
    state.isDuplicating = false;
  },
  [SET_SURVEY](state, survey) {
    state.survey = survey;
    // state.questions_count = survey.questionsCount;

    // Set timestamp for survey
    state.timestamps.survey = survey.updatedAt || survey.createdAt;

    state.isLoading = false;
    state.isSaving = false;
  },
  [SET_SURVEY_CUSTOM_DOMAIN](state, survey) {
    state.survey.custom_domain = survey.custom_domain;
    state.survey.addedCustomDomains.push(survey.custom_domain.verifiedDomain);

    // Set timestamp for survey
    state.timestamps.survey = survey.updatedAt;
  },
  [REMOVE_CUSTOM_DOMAIN](state, domain) {
    const index = state.survey.addedCustomDomains.findIndex(d => d === domain);
    if (index !== -1) {
      state.survey.addedCustomDomains.splice(index, 1);
    }

    // reset link to default
    if (state.survey.custom_domain.verifiedDomain === domain) {
      state.survey.custom_domain.verifiedDomain = "";
    }

    // no need to set timestamp here, because survey instance will update automatically using watch
  },
  [SET_TIMESTAMP_FOR_SURVEY](state, {updatedAt}) {
    state.timestamps.survey = updatedAt;
  },
  [SET_TIMESTAMP_FOR_QUESTION](state, {question_id, updatedAt}) {
    state.timestamps.questions[question_id] = updatedAt;
  },
  [SET_TIMESTAMP_FOR_SCREEN](state, {screen_id, updatedAt}) {
    state.timestamps.screens[screen_id] = updatedAt;
  },
  [SET_TIMESTAMP_FOR_CHOICE](state, {choice_id, updatedAt}) {
    state.timestamps.choices[choice_id] = updatedAt;
  },
  [SET_QUESTIONS](state, questions) {
    state.questions = questions.rows;
    state.questions_count = questions.count;
    state.questions_show_count = questions.show_count;

    // Set timestamp for each question
    questions.rows.forEach(question => {
      state.timestamps.questions[question._id] = question.updatedAt || question.createdAt;
    });

    // Set timestamp for each choice
    questions.rows.forEach(question => {
      question.choices.forEach(choice => {
        state.timestamps.choices[choice._id] = choice.updatedAt || choice.createdAt;
      });
    });

    state.isLoadingChild = false;
    state.isSaving = false;
  },
  [ADD_QUESTION](state, question) {
    const {position} = question;

    // Shift positions
    state.questions.forEach(q => {
      if (q.position >= position) {
        q.position += 1;
      }
    });

    state.questions.push(question);

    state.questions.sort((a, b) => a.position - b.position);

    // todo add timestamps to questions
  },
  [REORDER_QUESTIONS](state, questions) {
    state.questions.forEach(q => {
      const {position} = questions.find(qq => qq._id === q._id);
      q.position = position;
    });
  },
  [REMOVE_QUESTION](state, question_id) {
    const objIndex = state.questions.findIndex(obj => obj._id === question_id);
    if (objIndex !== -1) {
      state.questions.splice(objIndex, 1);
    }
  },
  [SET_SCREENS](state, screens) {
    const {welcomeScreen, thankyouScreens} = screens

    state.welcomeScreen = welcomeScreen
    state.thankyouScreens = thankyouScreens

    // Set timestamp for welcome screen
    if (welcomeScreen) {
      state.timestamps.screens[welcomeScreen._id] = welcomeScreen.updatedAt || welcomeScreen.createdAt;
    }

    // Set timestamp for each thank you screen
    thankyouScreens.forEach(screen => {
      state.timestamps.screens[screen._id] = screen.updatedAt || screen.createdAt;
    });
  },
  [ADD_SCREEN](state, payload) {
    const {type} = payload

    if (type === "welcome") {
      state.welcomeScreen = payload
    } else if (type === "thankyou") {
      state.thankyouScreens.push(payload)
    }
  },
  [REMOVE_SCREEN](state, screenId) {
    if (state.welcomeScreen?._id === screenId) {
      state.welcomeScreen = null
      return
    }

    const objIndex = state.thankyouScreens.findIndex(obj => obj._id === screenId)
    if (objIndex !== -1) {
      state.thankyouScreens.splice(objIndex, 1)
    }
  },
  [ADD_CHOICE](state, {question_id, choice}) {
    state.questions = state.questions.map(question => {
      if (question._id === question_id) {
        question.choices.push(choice);
      }
      return question;
    });
  },
  [REMOVE_CHOICE](state, {question_id, choice_id}) {
    state.questions = state.questions.map(question => {
      if (question._id === question_id) {
        const objIndex = question.choices.findIndex(obj => obj._id === choice_id);
        if (objIndex !== -1) {
          question.choices.splice(objIndex, 1);
        }
      }
      return question;
    });
  },
  [SET_RESPONSES](state, res) {
    if (!res.query_string.includes("offset=")) {
      state.responses = res.responses.results;
      state.responses_summary = res.responses.summary;
    } else {
      state.responses.push(...res.responses.results);
    }
    state.isLoadingChild = false;
  },
  [SET_COMMENTS](state, comments) {
    state.comments = comments.rows;
    state.comments_count = comments.count;
    state.isLoadingChild = false;
  },
  [RESET_RESPONSES_AND_COMMENTS](state) {
    state.responses = [];
    state.responses_summary = {};
    state.comments = [];
    state.comments_count = 0;

    state.questions.map(q => q.responses_count = 0);
  },
  [RESET_STATE](state) {
    Object.assign(state, getDefaultState())
  },
  [FETCH_START_RESULTS](state) {
    state.isFetchingResults = true;
  },
  [FETCH_FINISH_RESULTS](state) {
    state.isFetchingResults = false;
  },
  [SET_OPEN_RESPONSES](state, {question_id, responses}) {
    state.responses.map((objResponse) => {
      if (objResponse.question._id === question_id) {
        objResponse.answers.push(...responses)
      }

      return objResponse;
    });
  },
  [SET_LIMIT_REACHED_ALERT](state, value) {
    state.isLimitReachedAlert = value;
  },
  [SET_GOOGLE_CONNECTION](state, spreadsheetUrl) {
    const googleConnection = state.survey?.connections?.find(connection => connection.app === 'google-sheets');
    if (googleConnection) {
      googleConnection.active = true;
      googleConnection.value = spreadsheetUrl;
    }
  },
  [ADD_CALCULATED_FIELD](state, {survey_id, calculatedField}) {
    if (state.survey?._id === survey_id) {
      state.survey.calculatedFields.push(calculatedField);
    }
  },
  [SET_RESPONSES_DATE_FILTER](state, dates) {
    state.responsesDateFilter = {
      startDate: dates[0],
      endDate: dates[1],
    }
  },
  addCollaborator(state, {surveyId, collaborator}) {
    if (state.survey?._id === surveyId) {
      state.survey.collaborators.push(collaborator);
    }
  },
  removeCollaborator(state, {surveyId, collaboratorId}) {
    if (state.survey?._id === surveyId) {
      const collaboratorIndex = state.survey.collaborators.findIndex(collaborator => collaborator._id === collaboratorId);
      state.survey.collaborators.splice(collaboratorIndex, 1);
    }
  },
  updateCollaborator(state, {surveyId, collaborator}) {
    if (state.survey?._id === surveyId) {
      const collaboratorIndex = state.survey.collaborators.findIndex(c => c._id === collaborator._id);
      state.survey.collaborators.splice(collaboratorIndex, 1, collaborator);
    }
  },
}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
};
