<template>
  <div class="flex items-center">
    <div class="question_type_icon__wrapper flex-none mr-4">

      <slot name="icon"></slot>

    </div>
    <div
        class="question-block flex flex-grow pl-8 pr-4"
        :class="
             [
                disabledClass,
                activeClass,
                {'items-center': isComment || isWelcomeScreen || isThankyouScreen}
             ]"
    >
      <div
          class="flex-grow pr-8 mr-4 border-0 border-r border-solid border-gray-300"
          :class="{'pt-3 pb-8': isActiveBlock, 'py-5': !isActiveBlock}"
      >
        <div
            class="flex items-center justify-between"
            :class="{'mb-3': isActiveBlock || (!isComment && !isWelcomeScreen && !isThankyouScreen)}"
        >
          <div
              v-if="!isComment && !isWelcomeScreen && !isThankyouScreen"
              class="flex items-center"
          >
            <a-switch
                checked-children="On"
                un-checked-children="Off"
                v-model="question.active"
            />
            <div
                class="inline ml-6 mr-5 text-2xl font-light"
                :class="{'text-gray-300': !question.active}"
            >
              Question {{ questionIndex + 1 }}
              <div class="text-xs">id: {{ question._id }}</div>
            </div>
            <a-divider type="vertical" class="question-type-divider"/>
            <VQuestionTypeChange
                :question-type-selected="question.type"
                :question-types="questionTypes"
                :disabled="question.responses_count > 0"
                @makeChangeQuestionType="onChangeQuestionType"
            />
          </div>
          <div
              v-else-if="isWelcomeScreen"
              class="flex items-center"
          >
            <div class="flex items-center">
              <a-switch
                  checked-children="On"
                  un-checked-children="Off"
                  v-model="welcomeScreen.isActive"
                  @change="onWelcomeScreen"
              />
              <span
                  class="ml-6 text-2xl font-light"
                  :class="{'text-gray-300': !welcomeScreen.isActive}"
              >
								Welcome screen
							</span>
            </div>
          </div>
          <div
              v-else-if="isComment"
              class="flex items-center"
          >
            <div class="flex items-center">
              <a-switch
                  checked-children="On"
                  un-checked-children="Off"
                  v-model="survey.comment.active"
                  @change="onLastStepComment"
              />
              <div
                  class="inline ml-6 text-2xl font-light"
                  :class="{'text-gray-300': !survey.comment.active}"
              >
                Last step comment
              </div>
            </div>
          </div>
          <div
              v-else-if="isThankyouScreen"
              class="flex items-center"
          >
            <div class="flex items-center">
              <a-switch
                  checked-children="On"
                  un-checked-children="Off"
                  v-model="thankyouScreenData.isActive"
                  @change="onThankYouScreen"
              />
              <span
                  class="ml-6 text-2xl font-light"
                  :class="{'text-gray-300': !thankyouScreenData.isActive}"
              >
								Thank you screen
							</span>
            </div>
          </div>
          <div class="flex" v-if="!isComment && !isWelcomeScreen && !isThankyouScreen">
            <VButton
                type="link"
                size="large"
                icon="copy"
                class="btn-action_question link-default"
                style="height: auto;"
                :loading="isDuplicatingQuestion"
                @click="duplicateQuestion"
            >
              Duplicate
            </VButton>
            <VButton
                type="ghost-negative"
                size="large"
                icon="delete"
                class="btn-action_question -mr-3"
                style="height: auto;"
                :loading="isDeletingQuestion"
                @click="deleteQuestion"
            >
              Delete
            </VButton>
          </div>
          <div class="flex space-x-1 -mr-3" v-else-if="isThankyouScreen">
            <VButton
                type="link"
                size="large"
                icon="copy"
                class="btn-action_question link-default"
                style="height: auto;"
                :loading="isDuplicatingThankyou"
                @click="duplicateThankyouScreen"
            >
              Duplicate
            </VButton>
            <VButton
                type="ghost-negative"
                size="large"
                icon="delete"
                class="btn-action_question -mr-3"
                style="height: auto;"
                :loading="isDeletingThankyou"
                @click="deleteThankyouScreen"
            >
              Delete
            </VButton>
          </div>
          <div class="flex" v-else-if="isWelcomeScreen">
            <VButton
                type="ghost-negative"
                size="large"
                icon="delete"
                class="btn-action_question -mr-3"
                style="height: auto;"
                :loading="isDeletingWelcome"
                @click="deleteWelcomeScreen"
            >
              Delete
            </VButton>
          </div>
        </div>
        <div>

          <slot name="content">
            <component
                :is="questionTypes[question.type].component"
                :question.sync="question"
            ></component>

            <VQuestionConditionalLogic v-if="!isComment && !isWelcomeScreen && !isThankyouScreen" :question="question"/>

            <VCalculatedFieldsCollapse v-if="!isComment && !isWelcomeScreen && !isThankyouScreen"/>
          </slot>

        </div>
      </div>
      <VQuestionSort
          v-if="!isComment && !isWelcomeScreen && !isThankyouScreen"
          :question-index="questionIndex"
          :questions-length="questionsLength"
          @makeSort="onSort"
      />
      <div
          v-else
          class="flex flex-col justify-between flex-none text-gray-200"
      >
        <DragIcon
            class="opacity-25"
            :class="{'py-12': isActiveBlock}"
            style="font-size: 32px;"
        />
      </div>
    </div>
  </div>
</template>

<script>
import VQuestionSort from "@/components/SurveyEdit/Design/VQuestionSort";
import VTextArea from "@/components/VTextArea";
import VQuestionTypeChange from "@/components/SurveyEdit/Design/VQuestionTypeChange";
import VQuestionIcon from "@/components/VQuestionIcon";
import questionMixin from "@/mixins/question";
import QuestionSwipe from "@/components/SurveyEdit/Design/Questions/QuestionSwipe";
import QuestionNps from "@/components/SurveyEdit/Design/Questions/QuestionNps";
import QuestionMultiple from "@/components/SurveyEdit/Design/Questions/QuestionMultiple";
import QuestionRating from "@/components/SurveyEdit/Design/Questions/QuestionRating";
import QuestionComment from "@/components/SurveyEdit/Design/Questions/QuestionComment";
import WelcomeScreen from "@/components/SurveyEdit/Design/Questions/WelcomeScreen";
import {DragIcon} from "@/helpers/customIcons";
import {QUESTION_DELETE, QUESTION_EDIT} from "@/store/actions.type";
import _ from "lodash";
import {mapGetters} from "vuex";
import ThankyouScreen from "./Questions/ThankyouScreen";
import QuestionOpen from "./Questions/QuestionOpen";
import VQuestionConditionalLogic from "../../Question/VQuestionConditionalLogic";
import VButton from "../../VButton";
import VCalculatedFieldsCollapse from "../../Question/VCalculatedFieldsCollapse";
import {QUESTION_DUPLICATE} from "../../../store/actions.type";

export default {
  name: "VQuestion",
  props: {
    question: {type: Object},
    questionIndex: {type: Number},
    questionsLength: {type: Number},
    isComment: {type: Boolean, default: false},
    isWelcomeScreen: {type: Boolean, default: false},
    isThankyouScreen: {type: Boolean, default: false},
    thankyouScreenData: {type: Object},
    isDuplicatingThankyou: {type: Boolean, default: false},
    isDeletingThankyou: {type: Boolean, default: false},
    isDeletingWelcome: {type: Boolean, default: false},
  },
  components: {
    VButton,
    VQuestionSort,
    VTextArea,
    VQuestionTypeChange,
    VQuestionIcon,
    DragIcon,
    VQuestionConditionalLogic,
    VCalculatedFieldsCollapse,
  },
  mixins: [questionMixin],
  data() {
    return {
      questionTypes: {
        swipe: {
          typeName: "Swipe reply",
          blockClass: "question-block__swipe",
          defaultParams: {
            subtype: 'textWithImage',
            options: {}
          },
          component: QuestionSwipe,
          isQuestion: true,
        },
        nps: {
          typeName: "NPS",
          blockClass: "question-block__nps",
          defaultParams: {
            subtype: 'vertical',
            options: {scoreScale: 10}
          },
          component: QuestionNps,
          isQuestion: true,
        },
        multiple: {
          typeName: "Multiple answer",
          blockClass: "question-block__multiple",
          defaultParams: {
            subtype: '',
            options: {
              requiredAnswers: 1,
              isSingleSelection: false
            }
          },
          component: QuestionMultiple,
          isQuestion: true,
        },
        rating: {
          typeName: "Likert scale",
          blockClass: "question-block__rating",
          defaultParams: {
            subtype: 'smilesColorful',
            options: {
              ratingScale: 5
            }
          },
          component: QuestionRating,
          isQuestion: true,
        },
        open: {
          typeName: "Open answer",
          blockClass: "question-block__open",
          defaultParams: {
            subtype: 'singleLine',
            options: {
              required: true,
              placeholder: "",
              btnLabel: "",
            }
          },
          component: QuestionOpen,
          isQuestion: true,
        },
        comment: {
          blockClass: "question-block__comment",
          component: QuestionComment,
          isQuestion: false,
        },
        welcome_screen: {
          blockClass: "question-block__welcome-screen",
          component: WelcomeScreen,
          isQuestion: false,
        },
        thankyou_screen: {
          blockClass: "question-block__thankyou-screen",
          component: ThankyouScreen,
          isQuestion: false,
        }
      },
      isDeletingQuestion: false,
      isDuplicatingQuestion: false,
    }
  },
  created() {
    this.editQuestion = _.debounce(this.editQuestion, process.env.VUE_APP_NEXT_REQUEST_DELAY_MS);
  },
  watch: {
    computedQuestion: {
      handler: function (newOptions, oldOptions) {
        if (!_.isEqual(newOptions, oldOptions)) {
          this.editQuestion();
        }
      },
      deep: true
    }
  },
  computed: {
    ...mapGetters('survey', ['survey', 'welcomeScreen', 'questions_count']),
    //Use this from issue https://github.com/vuejs/vue/issues/2164#issuecomment-432872718
    //to resolve this note https://vuejs.org/v2/api/#vm-watch
    //it helps get old properties for object to compare with new properties
    computedQuestion: function () {
      return _.cloneDeep(this.question);
    },
    disabledClass: function () {
      let _class = '';
      if ((!this.isComment && !this.isWelcomeScreen && !this.isThankyouScreen && !this.question.active)
          || (this.isComment && !this.survey.comment.active)
          || (this.isWelcomeScreen && this.welcomeScreen && !this.welcomeScreen.isActive)
          || (this.isThankyouScreen && !this.thankyouScreenData.isActive)) {
        _class = 'question-block_disabled';
      }
      return _class;
    },
    activeClass: function () {
      let _class = this.questionTypes.comment.blockClass;
      if (!this.isComment && !this.isWelcomeScreen && !this.isThankyouScreen) {
        _class = this.questionTypes[this.question.type].blockClass
      } else if (this.isWelcomeScreen) {
        _class = this.questionTypes.welcome_screen.blockClass
      } else if (this.isThankyouScreen) {
        _class = this.questionTypes.thankyou_screen.blockClass
      }
      return _class;
    },
    isActiveBlock: function () {
      return (this.isWelcomeScreen && this.welcomeScreen && this.welcomeScreen.isActive) || (this.isComment && this.survey.comment.active) || (this.isThankyouScreen && this.thankyouScreenData.isActive);
    }
  },
  methods: {
    editQuestion() {
      this.$store.dispatch(`survey/${QUESTION_EDIT}`, {
        survey_id: this.$route.params.id,
        payload: {...this.question}
      })
    },
    deleteQuestion() {
      this.isDeletingQuestion = true;

      this.$store.dispatch(`survey/${QUESTION_DELETE}`, {
        survey_id: this.$route.params.id,
        questionId: this.question._id
      })
          .then(() => {
            this.$posthog.capture('Question Deleted', {
              surveyId: this.$route.params.id,
              questionsCount: this.questions_count - 1,
            });
          })
          .finally(() => {
            this.isDeletingQuestion = false;
          });
    },
    duplicateQuestion() {
      this.isDuplicatingQuestion = true;

      this.$store.dispatch(`survey/${QUESTION_DUPLICATE}`, {
        survey_id: this.$route.params.id,
        question_id: this.question._id,
      })
          .then((newQuestion) => {
            this.$emit('duplicate-question', newQuestion._id)
          })
          .finally(() => {
            this.isDuplicatingQuestion = false;
          });
    },
    onChangeQuestionType(questionType) {
      let {subtype, options} = this.questionTypes[questionType].defaultParams;
      this.question.type = questionType;
      this.question.subtype = subtype;
      this.question.options = {...options, ...this.question.options};
      this.editQuestion()
    },
    deleteWelcomeScreen() {
      this.$emit('delete-screen', {screenId: this.welcomeScreen._id, type: "welcomeScreen"})
    },
    deleteThankyouScreen() {
      this.$emit('delete-screen', {screenId: this.thankyouScreenData._id, type: "thankyouScreen"})
    },
    duplicateThankyouScreen() {
      this.$emit('duplicate-screen', this.thankyouScreenData._id)
    },
    onThankYouScreen() {
      this.$posthog.capture('Toggled Thank You screen', {
        surveyId: this.survey._id,
        value: this.thankyouScreenData.isActive,
      });
    },
    onWelcomeScreen() {
      this.$posthog.capture('Toggled Welcome screen', {
        surveyId: this.survey._id,
        value: this.welcomeScreen.isActive,
      });
    },
    onLastStepComment() {
      this.$posthog.capture('Toggled Last Step Comment', {
        surveyId: this.survey._id,
        value: this.survey.comment.active,
      });
    },
  }
}
</script>
