/**
 * user mutations
 */
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setAuth";
export const SET_USER = "setUser";
export const SET_USER_SETTINGS = "setUserSettings";

/**
 * billing
 */
export const SET_PLANS = "setPlans";
export const SUBSCRIPTION_LOADING_END = "subscriptionLoadingEnd";
export const SUBSCRIPTION_LOADING_START = "subscriptionLoadingStart";
export const SET_ACTIVATED_CODES = "setActivatedCodes";
export const ACTIVATE_CODE_BY_VALUE = "activateCodeByValue";

/**
 * survey mutations
 */
export const SET_SURVEYS = "setSurveys";
export const DELETE_SURVEY_MUTATION = "deleteSurveyMutation";
export const SET_SURVEY = "setSurvey";
export const SET_SURVEY_CUSTOM_DOMAIN = "setSurveyCustomDomain";
export const REMOVE_CUSTOM_DOMAIN = "mutationRemoveCustomDomain";
export const SET_TIMESTAMP_FOR_SURVEY = "setTimestampForSurvey";
export const SET_TIMESTAMP_FOR_QUESTION = "setTimestampForQuestion";
export const SET_TIMESTAMP_FOR_SCREEN = "setTimestampForScreen";
export const SET_TIMESTAMP_FOR_CHOICE = "setTimestampForChoice";
export const SET_TEMPLATES = "setTemplates";
export const SET_LIMIT_REACHED_ALERT = "setLimitReachedAlert";
export const RESET_RESPONSES_AND_COMMENTS = "resetResponsesAndComments";
export const SET_GOOGLE_CONNECTION = "setGoogleConnection";
export const ADD_CALCULATED_FIELD = "addCalculatedField";
export const SET_RESPONSES_DATE_FILTER = "setResponsesDateFilter";

/**
 * question mutations
 */
export const SET_QUESTIONS = "setQuestions";
export const ADD_QUESTION = "addQuestion";
export const REMOVE_QUESTION = "removeQuestion";
export const REORDER_QUESTIONS = "reorderQuestions";
export const SET_RESPONSES = "setResponses";
export const SET_OPEN_RESPONSES = "setOpenResponses";
export const SET_COMMENTS = "setComments";
export const ADD_CHOICE = "addChoice";
export const REMOVE_CHOICE = "removeChoice";

/**
 * screen mutations
 */
export const SET_SCREENS = "setScreens";
export const ADD_SCREEN = "addScreen";
export const REMOVE_SCREEN = "removeScreen";

/**
 * indicators
 */
export const FETCH_START = "setLoading";
export const FETCH_START_CHILD = "setLoadingChild";
export const SAVE_START = "setSaving";
export const SAVE_END = "finishSaving";
export const DUPLICATE_START = "setLoadingDuplicate"
export const DUPLICATE_END = "finishLoadingDuplicate"
export const FETCH_QUESTION_CHOICES_START = "setLoadingChoices"
export const AUTH_LOADING_START = "startLoadingAuth"
export const AUTH_LOADING_END = "finishLoadingAuth"
export const FETCH_START_RESULTS = "startFetchResults"
export const FETCH_FINISH_RESULTS = "finishFetchResults"
export const FETCH_SURVEYS_START = "startFetchSurveys"
export const FETCH_TEMPLATES_START = "startFetchTemplates"

/**
 * Reset
 */
export const RESET_STATE = "resetState";

/**
 * Plugins
 */
export const SET_PLUGINS = "setPlugins"

/**
 * Product updates
 */
export const SET_LAST_UPDATES = "setLastUpdates"
export const SET_LAST_UPDATES_SEEN = "setLastUpdatesSeen"

/**
 * Themes
 */
export const SET_THEMES = "setThemes"
export const CREATE_THEME_MUTATION = "createThemeMutation"
export const DELETE_THEME_MUTATION = "deleteThemeMutation"
export const DUPLICATE_THEME_MUTATION = "duplicateThemeMutation"

/**
 * Folder
 */
export const CREATE_FOLDER_MUTATION = "createFolderMutation"
export const UPDATE_FOLDER_MUTATION = "updateFolderMutation"
export const DELETE_FOLDER_MUTATION = "deleteFolderMutation"
