<template>
  <div class="relative w-full px-8 py-8">

    <a-alert
        v-if="showSurveyAlert"
        type="info"
        class="mb-6"
        @close="handleAlertClose"
        closable
    >
      <div slot="message" class="flex justify-between items-center w-full pr-4">
        <span>Answer <b>4</b> quick questions to help us improve your experience!</span>
        <span data-ms-popup="67a0545ba4d739001239fba1" data-ms-button-text="Answer 4 questions"
              data-ms-button-type="inline" data-ms-button-style="green" data-ms-button-size="lg"
              data-ms-button-shape="text" data-ms-button-with-icon="false"
              style="padding-left: 0px; padding-right: 0px;"></span>
      </div>
    </a-alert>

    <div class="flex justify-between items-center mb-6">
      <div class="flex items-center space-x-4">
        <div v-if="$route.params.folderId" role="button" class="text-dark_gray-500 hover:text-blue-500">
          <a-icon type="arrow-left" @click="$router.go(-1)"/>
        </div>

        <div class="flex items-center space-x-4">
          <h2 v-if="!$route.params.folderId" class="text-dark_gray-500 text-3xl mb-0">
            {{ $route.meta.pageTitle }}
          </h2>
          <a-input
              v-else-if="$route.params.folderId && canEdit({folderId: $route.params.folderId})"
              v-model="folderName"
              :max-length="50"
              size="large"
              placeholder="Folder name"
              class="folder-name-input"
              style="width: 330px"
          />
          <span v-else-if="!canEdit({folderId: $route.params.folderId})" class="text-2xl">
            {{ folderName || "Untitled folder" }}
          </span>
        </div>
      </div>

      <div class="flex space-x-4">
        <a-tooltip
            v-if="$route.params.folderId && canInvite({folderId: $route.params.folderId})"
            title="Collaborators">
          <VButton
              type="default"
              size="large"
              icon="team"
              @click="onInviteUser(null, $route.params.folderId)"/>
        </a-tooltip>
        <a-tooltip
            v-if="!$route.params.folderId"
            title="New folder">
          <VButton
              type="default"
              size="large"
              icon="folder-add"
              :loading="isCreatingFolder"
              @click="onCreateFolder"/>
        </a-tooltip>
        <a-tooltip
            v-if="!$route.params.folderId || canEdit({folderId: $route.params.folderId})"
            title="New survey">
          <VButton
              type="default-primary"
              size="large"
              icon="plus"
              @click="isTemplatesModalVisible = true"/>
        </a-tooltip>
      </div>
    </div>

    <a-spin :spinning="isLoadingSurveys">
      <SurveysAndFolders
          :can-create="!$route.params.folderId || canEdit({folderId: $route.params.folderId})"
          @create-survey="isTemplatesModalVisible = true"
          @duplicate-survey="onDuplicateSurvey"
          @move-survey="onMoveSurvey"/>
    </a-spin>

    <a-modal
        v-model="isTemplatesModalVisible"
        width="100%"
        :dialog-style="{ top: '0', padding: '0' }"
        :body-style="{backgroundColor: 'var(--color-layout)', paddingTop: '32px', paddingBottom: '80px', minHeight: '100vh' }"
        :footer="null">
      <div class="m-auto" style="max-width: 1280px;">
        <Templates/>
      </div>
    </a-modal>

    <InviteCollaboratorModal/>

    <NeedUpgradeModal/>

    <NewFeatureModal/>
  </div>
</template>

<script>
import _ from "lodash";
import {mapGetters} from "vuex";
import {FETCH_TEMPLATES} from "@/store/actions.type";
import SurveysAndFolders from "../components/Dashboard/SurveysAndFolders";
import {CREATE_FOLDER, FETCH_SURVEYS, SURVEY_CLONE, SURVEY_EDIT, UPDATE_FOLDER} from "../store/actions.type";
import Templates from "../components/Dashboard/Templates";
import VButton from "../components/VButton";
import InviteCollaboratorModal from "../components/Modals/InviteCollaboratorModal";
import NeedUpgradeModal from "../components/Modals/NeedUpgradeModal";
import {CollaboratorsMixin} from "../mixins/collaborators.mixin";
import NewFeatureModal from "../components/Modals/NewUpdateModal";

export default {
  name: "Dashboard",
  components: {SurveysAndFolders, Templates, VButton, InviteCollaboratorModal, NeedUpgradeModal, NewFeatureModal},
  mixins: [CollaboratorsMixin],
  created() {
    this.fetchData();
    this.onFolderNameChange = _.debounce(this.onFolderNameChange, process.env.VUE_APP_NEXT_REQUEST_DELAY_MS);
  },
  mounted() {
    if (!this.templates.length && !this.templateGroups.length) {
      this.$store.dispatch(`home/${FETCH_TEMPLATES}`);
    }
    if (!document.querySelector('script[src="https://embed.getmetasurvey.com/embed.js"]')) {
      const script = document.createElement('script')
      script.src = 'https://embed.getmetasurvey.com/embed.js'
      script.defer = true
      document.head.appendChild(script)
      this.metasurveyScript = script
    }

    // Survey alert
    this.checkAlertState()
    window.addEventListener('message', (event) => {
      if (event.data?.type === 'ms-survey-completed') {
        this.handleSurveyCompletion()
      }
    })
  },
  beforeDestroy() {
    if (this.metasurveyScript) {
      this.metasurveyScript.remove()
      this.metasurveyScript = null
    }
    // Survey alert
    window.removeEventListener('message', this.handleSurveyCompletion)
  },
  watch: {
    '$route': 'fetchData',
    folderName: {
      handler(newValue, oldValue) {
        if (newValue !== oldValue && oldValue !== undefined) {
          this.onFolderNameChange(newValue);
        }
      },
    },
  },
  data() {
    return {
      isTemplatesModalVisible: false,
      isCreatingFolder: false,
      folderName: undefined,
      isLoadingSurveys: false,
      metasurveyScript: null,
      showSurveyAlert: false,
    }
  },
  computed: {
    ...mapGetters('survey', ["isDuplicating"]),
    ...mapGetters('auth', ["features"]),
    ...mapGetters('home', ["getFolderById", "folders", "templates", "templateGroups"]),
    ...mapGetters('collaborators', ["canInvite", "canEdit"]),
  },
  methods: {
    async fetchData() {
      this.isLoadingSurveys = true;

      try {
        await this.$store.dispatch(`home/${FETCH_SURVEYS}`, {folderId: this.$route.params.folderId});

        if (this.$route.params.folderId) {
          this.folderName = this.getFolderById(this.$route.params.folderId)?.name || '';
        }
      } finally {
        this.isLoadingSurveys = false;
      }
    },
    async onCreateFolder() {
      this.isCreatingFolder = true;

      try {
        await this.$store.dispatch(`home/${CREATE_FOLDER}`);
      } finally {
        this.isCreatingFolder = false;
      }
    },
    onFolderNameChange(value) {
      this.$store.dispatch(`home/${UPDATE_FOLDER}`, {folderId: this.$route.params.folderId, name: value});
    },
    async onMoveSurvey({survey, folderId}) {
      this.isLoadingSurveys = true;

      try {
        await this.$store.dispatch(`survey/${SURVEY_EDIT}`, {
          survey_id: survey._id,
          options: {...survey, folderId}
        });
        await this.fetchData();
      } finally {
        this.isLoadingSurveys = false;
      }
    },
    async onDuplicateSurvey({survey}) {
      this.isLoadingSurveys = true;

      try {
        await this.$store.dispatch(`survey/${SURVEY_CLONE}`, {
          survey_id: survey._id,
          folderId: this.$route.params.folderId
        });
        await this.fetchData();
      } finally {
        this.isLoadingSurveys = false;
      }
    },
    // Survey alert
    handleAlertClose() {
      this.showSurveyAlert = false
      const expiryDate = new Date()
      expiryDate.setDate(expiryDate.getDate() + 7)
      localStorage.setItem('surveyAlertState', JSON.stringify({
        hidden: true,
        expiryDate: expiryDate.toISOString()
      }))
    },
    handleSurveyCompletion() {
      this.showSurveyAlert = false
      localStorage.setItem('surveyAlertState', JSON.stringify({
        hidden: true,
        completed: true
      }))
    },
    checkAlertState() {
      const state = localStorage.getItem('surveyAlertState')
      if (!state) {
        this.showSurveyAlert = true
        return
      }

      const {hidden, completed, expiryDate} = JSON.parse(state)

      if (completed) {
        this.showSurveyAlert = false
        return
      }

      if (hidden && expiryDate) {
        this.showSurveyAlert = new Date() > new Date(expiryDate)
        return
      }

      this.showSurveyAlert = true
    }
  }
}
</script>

<style lang="less" scoped>
.folder-name-input {
  border-color: transparent;
  background-color: @v-color-gray-100;
  font-size: @v-heading-3-size;
  font-weight: 400;
  line-height: normal;
  height: auto;
  padding: 0 12px;

  &:hover,
  &:focus {
    background-color: transparent;
    border-color: @v-color-gray-300;
    box-shadow: none;
  }
}
</style>
