<template>
  <div class="w-7/12 pt-3 pb-10 px-10">
    <a-collapse :bordered="false" expandIconPosition="right" class="space-y-4" style="background-color: transparent;">
      <template #expandIcon="props">
        <a-icon type="down" :rotate="props.isActive ? 180 : 0"
                style="color: var(--color-gray-500); font-size: 20px;"/>
      </template>

      <a-collapse-panel v-for="connection in connections"
                        :key="connection.title"
                        :disabled="connection.needUpgrade"
                        style="border: 1px solid var(--color-gray-300); border-radius: 8px;">

        <template slot="header">
          <VProElement :needUpgrade="connection.needUpgrade"
                       :name="`integrate with ${connection.title}`"
                       :is-plus-addon="false">
            <div class="py-4 px-6 flex items-center space-x-1.5 text-xl">
              <VIcon :name="connection.iconName"/>
              <div class="text-character_gray-400">
                {{ connection.title }}
              </div>
              <a-tag v-if="connection.activeItems" color="green" class="text-sm">
                {{ connection.activeItems }} active
              </a-tag>
              <VLabel v-if="connection.needUpgrade" type="feature"></VLabel>
            </div>
          </VProElement>
        </template>

        <div v-if="!connection.needUpgrade" class="py-4 px-6">
          <component :is="connection.component"/>
        </div>

      </a-collapse-panel>
    </a-collapse>
  </div>
</template>

<script>
import VIcon from "../../VIcon"
import WebhooksConnection from "./Connections/WebhooksConnection"
import PabblyConnection from "./Connections/PabblyConnection"
import {mapGetters} from "vuex";
import GoogleSheetsConnection from "./Connections/GoogleSheetsConnection";
import VLabel from "../../VLabel";
import VProElement from "../../VProElement";

export default {
  name: "SurveyDesignConnect",
  components: {VIcon, VLabel, VProElement},
  computed: {
    ...mapGetters('survey', ['survey']),
    connections: function () {
      return [
        {
          title: "Webhooks",
          slug: this.$constants("WEBHOOK"),
          iconName: "webhooks",
          component: WebhooksConnection,
          activeItems: this.survey.connections.filter(w => w.app === this.$constants("WEBHOOK") && w.active && w.value)?.length,
          needUpgrade: !this.survey.features.webhooks,
        },
        {
          title: "Pabbly",
          slug: this.$constants("PABBLY"),
          iconName: "pabbly",
          component: PabblyConnection,
          activeItems: this.survey.connections.filter(w => w.app === this.$constants("PABBLY") && w.active && w.value)?.length,
          needUpgrade: !this.survey.features.webhooks,
        },
        {
          title: "Google Sheets",
          slug: this.$constants("GOOGLE_SHEETS"),
          iconName: "google-sheets",
          component: GoogleSheetsConnection,
          activeItems: this.survey.connections.filter(w => w.app === this.$constants("GOOGLE_SHEETS") && w.active && w.value)?.length,
          needUpgrade: !this.survey.features.google_sheets,
        },
      ]
    }
  },
}
</script>

<style scoped>

</style>
