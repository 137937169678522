<template>
  <div class="bg-black relative flex flex-col items-center justify-center w-full p-8">
    <div class="flex z-20 relative">
      <a :href="landingUrl" target="_blank" class="inline mx-auto">
        <VIcon name="main-logo-light" style="font-size: 250px;"/>
      </a>
    </div>

    <div class="relative z-20 flex flex-col justify-between bg-white p-12 rounded-xl my-auto"
         style="box-shadow: 5px 4px 15px rgba(0,0,0,.15);">
      <div class="space-y-8" style="width: 300px;">
        <h1 class="font-bold text-3xl leading-tight text-center mb-0">{{ title }}</h1>
        <slot></slot>
      </div>
    </div>

    <div class="cover-image z-10"></div>
  </div>
</template>

<script>
import VIcon from "./VIcon";

export default {
  name: "VAuthBlock",
  props: {
    title: {
      type: String
    }
  },
  components: {VIcon},
  data() {
    return {
      landingUrl: process.env.VUE_APP_LANDING_URL
    }
  }
}
</script>

<style lang="less" scoped>
.cover-image {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: url('../assets/images/valentine-cover.jpg') 50% 50% no-repeat;
  background-size: cover;
}
</style>
