<template>
  <div>
    <ActivateCodes/>

    <VSubscriptionLayout :data="currentPlan">
      <template #plan-extra>
        <span class="text-warning-500">Lifetime access</span>
        <span v-if="isUserLifetimeWithAddon" class="text-violet-500">
          <a-icon type="star" theme="filled"/>
          Business add-on
        </span>
      </template>


      <template #plans>
        <PlusAddon v-if="isUserLifetimeWithAddon"/>
      </template>
    </VSubscriptionLayout>

  </div>
</template>

<script>
import VSubscriptionLayout from "../../layouts/VSubscriptionLayout";
import {mapGetters} from "vuex";
import {subscriptionMixin} from "../../mixins/subscription";
import ActivateCodes from "./ActivateCodes";
import PlusAddon from "./PlusAddon";

export default {
  name: "SubscriptionLifetime",

  components: {VSubscriptionLayout, ActivateCodes, PlusAddon},

  mixins: [subscriptionMixin],

  computed: {
    ...mapGetters('auth', ["responsesLimit", "isUserLifetimeWithAddon"]),

    currentPlan: function () {
      const responsesLimit = this.getLimits()
      return {
        icon: this.getPlanIcon(),
        name: this.getPlanName(),
        details: `${responsesLimit.availableCount} responses, unlimited surveys and questions`,
        status: this.getStatus(),
        responsesLimit
      }
    },
  },

}
</script>
