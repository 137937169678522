<template>
  <div style="padding-left: 68px;" class="w-full flex justify-between">
    <div class="space-y-7" style="max-width: 305px;">
      <div class="text-xl leading-tight text-violet-500">
        <a-icon type="star" theme="filled" class="text-violet-500"/>
        Business Add-on
      </div>
      <div v-if="!isAddonActive" class="text-base leading-tight" style="color: rgba(127, 140, 167, 1)">
        Your cancelled subscription will remain active until {{ user.subscription.cancelledFrom | moment('ll') }}
      </div>
    </div>

    <VPlusAddonFeatures class="text-base space-y-2"/>

    <div>
      <div v-if="isAddonActive"
           class="space-y-3 text-center"
           style="color: #7F8CA7;">
        <VButton type="default-negative" style="height: 36px;" @click="onCancel">
          <a-icon type="stop"/>
        </VButton>
        <div class="text-base leading-none">Cancel</div>
      </div>
    </div>
  </div>
</template>

<script>
import {subscriptionMixin} from "../../mixins/subscription";
import {mapGetters} from "vuex";
import VButton from "../VButton";
import VPlusAddonFeatures from "../VPlusAddonFeatures";

export default {
  name: "PlusAddon",

  components: {VButton, VPlusAddonFeatures},

  mixins: [subscriptionMixin],

  computed: {
    ...mapGetters('auth', ["user"]),
    isAddonActive() {
      return !this.user.subscription?.cancelledFrom;
    }
  },
}
</script>

<style scoped>

</style>
