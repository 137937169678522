<template>
  <div class="relative w-full px-8 py-8">
    <a-spin :spinning="isLoading || isDuplicating" :tip="loadingText">
      <div class="flex justify-between mb-6">

        <div class="flex items-center space-x-4">
          <div role="button" class="text-dark_gray-500 hover:text-blue-500">
            <a-icon type="arrow-left" @click="$router.go(-1)"/>
          </div>

          <div class="flex items-center space-x-4">
            <a-input
                v-if="canEditSurvey"
                v-model="survey.title"
                id="survey-title"
                size="large"
                placeholder="Survey name"
                :maxLength="100"
                style="width: 330px"/>
            <span v-else class="text-2xl">
              {{ survey.title || "Untitled survey" }}
            </span>
          </div>
        </div>

        <VSaveIndicator v-if="canEditSurvey"/>
      </div>


      <a-tabs class="w-full"
              size="large"
              :activeKey="activeTab"
              :animated="false"
              @change="onChangeTab"
      >
        <a-tab-pane v-for="tab in tabs"
                    :key="tab.key"
                    :disabled="tab.disabled"
        >
          <div slot="tab">
            <a-icon :type="tab.icon"/>
            {{ tab.name }}
          </div>
          <component v-if="!isLoading" :is="tab.component"></component>
        </a-tab-pane>

        <div
            slot="tabBarExtraContent"
            class="flex justify-end space-x-4">
          <a-tooltip v-if="activeTab !== 'design'" title="Refresh results">
            <VButton
                type="default"
                size="large"
                icon="reload"
                :loading="isLoadingChild"
                @click="updateResults(survey._id)"/>
          </a-tooltip>
          <a-tooltip v-if="activeTab !== 'design'" title="Download results in CSV">
            <VButton
                type="default"
                size="large"
                icon="download"
                :disabled="survey.features && !survey.features.export_results"
                :loading="isFetchingResults"
                @click="exportResults(survey._id)"/>
          </a-tooltip>
          <a-tooltip v-if="activeTab !== 'design' && canEditSurvey" title="Reset results">
            <VButton
                type="default-negative-hover"
                size="large"
                :loading="isResettingResults"
                @click="resetResults(survey._id)"
                style="width: 44px; height: 44px; padding: 0;">
              <VIcon name="clear" style="font-size: 20px;"/>
            </VButton>
          </a-tooltip>
          <a-tooltip v-if="activeTab === 'design'" title="Copy survey link">
            <VButton
                size="large"
                type="default"
                :icon="isCopying ? 'check' : 'link'"
                :disabled="isCopying"
                ref="btnCopy"
                style="font-size: 12px;"
                @click="doCopy"/>
          </a-tooltip>
          <a-tooltip v-if="activeTab === 'design'" title="Preview survey">
            <VButton
                size="large"
                type="default"
                icon="eye"
                @click="previewSurvey"/>
          </a-tooltip>
          <a-dropdown v-if="activeTab === 'design'" placement="bottomRight">
            <a-menu slot="overlay">
              <a-menu-item key="duplicate">
                <div class="text-base space-x-2"
                     @click="onDuplicateSurvey"
                >
                  <a-icon type="copy"/>
                  <span>Duplicate</span>
                </div>
              </a-menu-item>
              <a-menu-item v-if="canInviteToSurvey" key="collaborators">
                <div class="text-base space-x-2"
                     @click="onInviteUser(survey._id)"
                >
                  <a-icon type="team"/>
                  Collaborators
                </div>
              </a-menu-item>
              <a-menu-divider/>
              <a-menu-item v-if="canLeaveSurvey" key="leave">
                <div
                    class="text-base space-x-2"
                    @click.prevent="onLeave(getCollaborator({surveyId: survey._id})._id, survey._id, null)">
                  <a-icon type="logout"/>
                  Leave
                </div>
              </a-menu-item>
              <a-menu-item v-if="canEditSurvey" key="delete">
                <div class="text-base text-negative space-x-2"
                     @click.prevent="deleteSurvey(survey._id)"
                >
                  <a-icon type="delete"/>
                  Delete
                </div>
              </a-menu-item>
            </a-menu>
            <VButton
                size="large"
                type="default"
                icon="ellipsis"/>
          </a-dropdown>
        </div>

      </a-tabs>


    </a-spin>

    <div id="components-back-top-demo-custom">
      <a-back-top/>
    </div>

    <InviteCollaboratorModal v-if="canInvite({surveyId: survey._id})"/>

    <NeedUpgradeModal/>
  </div>
</template>

<script>
import SurveyDesignOptions from "@/components/SurveyEdit/Design/SurveyDesignOptions";
import {mapGetters} from "vuex";
import {FETCH_SURVEY} from "@/store/actions.type";
import {surveyMixin} from "@/mixins/survey";
import SurveyEditDesign from "@/views/SurveyEditDesign";
import SurveyEditAnalytic from "@/views/SurveyEditAnalytic";
import SurveyEditComment from "@/views/SurveyEditComment";
import VSaveIndicator from "@/components/VSaveIndicator";
import VButton from "@/components/VButton";
import VIcon from "../components/VIcon";
import {FETCH_RESPONSES, RESET_RESULTS, SURVEY_CLONE} from "../store/actions.type";
import InviteCollaboratorModal from "../components/Modals/InviteCollaboratorModal";
import NeedUpgradeModal from "../components/Modals/NeedUpgradeModal";
import {CollaboratorsMixin} from "../mixins/collaborators.mixin";
import {RESET_STATE} from "../store/mutations.type";

export default {
  name: "SurveyEdit",
  components: {VButton, SurveyDesignOptions, VSaveIndicator, VIcon, InviteCollaboratorModal, NeedUpgradeModal},
  mixins: [surveyMixin, CollaboratorsMixin],
  created() {
    this.fetchData();
  },
  beforeDestroy() {
    this.$store.commit(`survey/${RESET_STATE}`);
  },
  watch: {
    '$route.params.id': 'fetchData',
  },
  data() {
    return {
      isResettingResults: false,
      isCopying: false,
    }
  },
  computed: {
    ...mapGetters('survey', ["survey", "isLoadingChild", "responses", "isLoading", "isDuplicating", "isFetchingResults"]),
    ...mapGetters('collaborators', ["canEdit", "canInvite", "getCollaborator", "permissions"]),
    canEditSurvey() {
      return this.canEdit({surveyId: this.survey._id}) || [this.permissions.admin.value, this.permissions.edit.value].includes(this.survey.folderPermission);
    },
    canInviteToSurvey() {
      return this.canInvite({surveyId: this.survey._id});
    },
    canLeaveSurvey() {
      const collaborator = this.getCollaborator({surveyId: this.survey._id});
      return Object.keys(collaborator).length > 0 && !collaborator.isOwner;
    },
    tabs: function () {
      let tabsArray = [
        {
          name: "Analytics",
          key: "analytic",
          icon: "bar-chart",
          component: SurveyEditAnalytic,
          disabled: false
        },
        {
          name: "Comments",
          key: "comments",
          icon: "message",
          component: SurveyEditComment,
          disabled: false
        },
      ]

      if (this.canEditSurvey) {
        tabsArray.unshift({
          name: "Survey",
          key: "design",
          icon: "edit",
          component: SurveyEditDesign,
          disabled: false
        })
      }

      return tabsArray;
    },
    loadingText: function () {
      return this.isDuplicating ? 'Duplicating survey...' : 'Loading survey...';
    },
    activeTab: function () {
      let activeTab = 'design';
      if (this.$route.query.tab === 'analytic' || this.$route.query.tab === 'comments') {
        activeTab = this.$route.query.tab;
      }
      return activeTab;
    }
  },
  methods: {
    async fetchData() {
      this.$store.dispatch(`survey/${FETCH_SURVEY}`, this.$route.params.id)
          .then(() => {
            let documentTitle;
            const {title} = this.survey;
            documentTitle = `${title} – ${process.env.VUE_APP_BASE_TITLE}`;
            if (!title) {
              documentTitle = `New Survey – ${process.env.VUE_APP_BASE_TITLE}`;
            }
            window.document.title = documentTitle;

            // Redirect to analytic tab if user can't edit survey
            if (!this.canEditSurvey) {
              this.onChangeTab('analytic')
            }
          })
          .catch(() => {
            this.$router.push('/404')
          });
    },
    onChangeTab(activeKey) {
      this.$router.replace({path: `${this.$route.path}`, query: {tab: activeKey}})
    },
    onDuplicateSurvey() {
      this.$store.dispatch(`survey/${SURVEY_CLONE}`, {survey_id: this.survey._id})
          .then(({_id}) => {
            this.$router.push('/survey/' + _id);
            this.$posthog.capture('Survey Duplicated', {oldSurveyId: this.survey._id, newSurveyId: _id});
          });
    },
    resetResults(surveyId) {
      this.$confirm({
        title: 'Reset survey results?',
        content: `This will delete all survey responses and comments. This cannot be undone.`,
        okText: 'Reset',
        cancelText: 'No, cancel',
        okButtonProps: {props: {type: 'danger', size: 'default'}},
        centered: true,
        maskClosable: true,
        class: 'modal-delete-survey',
        icon: () => (<a-icon type="warning" style="color: var(--color-negative)"/>),
        onOk: () => {
          this.isResettingResults = true
          this.$store.dispatch(`survey/${RESET_RESULTS}`, {survey_id: surveyId})
              .finally(() => {
                this.isResettingResults = false
                this.$posthog.capture('Survey Results Reset', {surveyId});
              })
        },
      })
    },
    updateResults(surveyId) {
      this.$store.dispatch(`survey/${FETCH_RESPONSES}`, {survey_id: surveyId})
      // this.$root.$emit('reloadResults')
    },
    doCopy() {
      const sharingLink = this.getSharableLink()

      this.$copyText(sharingLink).then(() => {
        this.isCopying = true
        setTimeout(() => {
          this.isCopying = false
        }, 5000)

        this.$posthog.capture('Copied Survey link');
      })
    },
  }
}
</script>

<style lang="less">
#survey-title {
  border-color: transparent !important;
  background-color: @v-color-gray-100 !important;
  font-size: @v-heading-3-size;
  //margin-left: -11px;
  font-weight: 400;
  line-height: normal !important;
  height: auto !important;
  padding: 0 11px !important;

  &:hover,
  &:focus {
    background-color: transparent !important;
    border-color: @v-color-gray-300 !important;
    box-shadow: none !important;
  }

  & + .ant-input-suffix {
    right: 23px;
  }
}

#components-back-top-demo-custom .ant-back-top {
  bottom: 100px;
  right: 40px;
}
</style>
