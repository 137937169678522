<template>
  <div class="pb-5 mt-4 w-full">

    <div class="flex space-x-4 px-10">
      <VButton type="default"
               size="large"
               class="w-full"
               style="height: 48px;"
               @click="onOpenImportModal(tabs.import_from_csv)"
      >
        <a-icon type="unordered-list" class="text-blue-500"/>
        Import from CSV
      </VButton>
      <VButton type="default"
               size="large"
               class="w-full"
               style="height: 48px;"
               @click="onOpenImportModal(tabs.import_images)"
      >
        <a-icon type="appstore" class="text-magenta-500"/>
        Images to questions
      </VButton>
    </div>

    <a-divider :dashed="true" style="margin: 32px 0; border-color: var(--color-neutral-gray-300)"/>

    <VQuestion
        v-if="welcomeScreen && !isLoadingScreens"
        :is-welcome-screen="true"
        :is-deleting-welcome="isDeletingWelcome"
        @delete-screen="onDeleteScreen"
        class="px-10"
    >
      <template #icon>
        <VQuestionIcon v-if="welcomeScreen.isActive" type="welcome"/>
      </template>
      <template #content>
        <WelcomeScreen/>
      </template>
    </VQuestion>
    <div v-else-if="!isLoadingScreens">
      <div class="ml-32 px-10 mt-6">
        <a-button
            type="default"
            size="large"
            icon="plus"
            block
            class="btn-add"
            style="text-transform: uppercase;"
            :loading="isCreatingWelcome"
            @click="onCreateWelcome"
        >
          Add Welcome screen
        </a-button>
      </div>
    </div>

    <a-divider :dashed="true" style="margin: 32px 0; border-color: var(--color-neutral-gray-300)"/>

    <div class="px-10">
      <a-spin :spinning="isLoadingChild">
        <Draggable v-if="questions_count > 0"
                   v-bind="dragOptions"
                   v-model="questions"
                   @start="onDragSortStart"
                   @end="onDragSortEnd"
        >
          <transition-group tag="div" :name="!drag ? 'flip-list' : null">
            <div v-for="(question, index) in questions"
                 :key="question._id"
                 class="mb-6"
            >
              <VQuestion
                  :ref="`question-${question._id}`"
                  :question.sync="question"
                  :question-index="Number(index)"
                  :questions-length="Number(questions_count)"
                  @changeSort="onClickSort"
                  @duplicate-question="scrollToQuestion"
              >
                <template #icon>
                  <VQuestionIcon :type="question.type" :subtype="question.subtype"/>
                </template>
              </VQuestion>

              <div
                  v-if="index < questions_count - 1"
                  class="ml-32 mt-6 px-10"
              >
                <a-button
                    type="default"
                    size="large"
                    icon="plus"
                    block
                    class="btn-add"
                    style="text-transform: uppercase;"
                    :loading="isCreatingQuestion"
                    @click="createQuestion(question.position + 1)"
                >
                  {{ 'Add question here' }}
                </a-button>
              </div>
            </div>
          </transition-group>
        </Draggable>
        <TheEmptyState v-else/>

        <div
            class="ml-32 px-10"
            :class="{'mt-0 bg-gray-50 pb-6 px-8 rounded-b': questions_count === 0, 'mt-6': questions_count > 0}"
        >
          <a-button
              :type="questions_count > 0 ? 'default' : 'primary'"
              size="large"
              icon="plus"
              block
              :class="questions_count > 0 ? 'btn-add' : ''"
              style="text-transform: uppercase;"
              :loading="isCreatingQuestion"
              :disabled="questions_count > questions_show_count"
              @click="createQuestion"
          >
            {{ questions_count > 0 ? 'New question' : 'Add first question' }}
          </a-button>
        </div>
      </a-spin>
    </div>

    <VQuestion :isComment="true" class="mt-8 px-10">
      <template #icon>
        <VQuestionIcon v-if="survey.comment.active" type="comment"/>
      </template>
      <template #content>
        <QuestionComment/>
      </template>
    </VQuestion>

    <a-divider :dashed="true" style="margin: 32px 0; border-color: var(--color-neutral-gray-300)"/>

    <div v-if="thankyouScreens.length && !isLoadingScreens">
      <div v-for="thankyouScreen in thankyouScreens"
           :key="thankyouScreen._id"
           class="mb-6"
           :ref="thankyouScreen._id"
      >
        <VQuestion
            :is-thankyou-screen="true"
            :thankyou-screen-data="thankyouScreen"
            :is-duplicating-thankyou="isDuplicatingThankyou"
            :is-deleting-thankyou="isDeletingThankyou"
            @delete-screen="onDeleteScreen"
            @duplicate-screen="onDuplicateScreen"
            class="px-10"
        >
          <template #icon>
            <VIcon v-if="thankyouScreen.isActive" name="thankyou-screen"/>
          </template>
          <template #content>
            <ThankyouScreen :thankyou-screen="thankyouScreen"/>
          </template>
        </VQuestion>
      </div>
    </div>
    <div v-if="!isLoadingScreens">
      <div class="ml-32 px-10 mt-6">
        <a-button
            type="default"
            size="large"
            icon="plus"
            block
            class="btn-add"
            style="text-transform: uppercase;"
            :loading="isCreatingThankyou"
            @click="onCreateThankyouScreen"
        >
          Add Thank You screen
        </a-button>
      </div>
    </div>


    <a-modal :visible="modalImportQuestions.visible"
             :okText="!isImporting ? 'Import' : 'Importing...'"
             :closable="modalImportQuestions.closable"
             :mask-closable="modalImportQuestions.maskClosable"
             :width="modalImportQuestions.width"
             :ok-button-props="{ props: { disabled: !canImport, loading: isImporting } }"
             :destroyOnClose="!isImporting"
             centered
             :afterClose="onCloseModal"
             @ok="onImport"
             @cancel="onCloseModal"
    >
      <a-tabs class="w-full"
              v-model="activeTab"
              :animated="false"
      >
        <a-tab-pane :key="tabs.import_from_csv">
          <div slot="tab">
            <a-icon type="file-text"/>
            <span>{{ tabs.import_from_csv }}</span>
          </div>
          <div class="pt-6 pb-5">
            <VCsvImport v-model="importedQuestionsFromCsv" :map-fields="['question']"></VCsvImport>
          </div>
        </a-tab-pane>
        <a-tab-pane :key="tabs.import_images">
          <div slot="tab">
            <a-icon type="picture"/>
            <span>{{ tabs.import_images }}</span>
          </div>
          <div class="pt-6 pb-5">
            <VImagesImport v-model="importedImages"/>
          </div>
        </a-tab-pane>
      </a-tabs>
      <a-alert v-if="isImporting"
               type="info"
               show-icon
      >
        <p slot="message" class="font-normal mb-2">Wait a sec! We are importing your questions...</p>
        <div slot="description" class="font-light">
          <p>Please don't worry about loading indicator, once the import is completed you will be notified.</p>
        </div>
      </a-alert>
    </a-modal>


  </div>
</template>

<script>
import {
  FETCH_QUESTIONS,
  QUESTION_CREATE,
  QUESTIONS_UPDATE_POSITION,
  IMPORT_QUESTIONS_FROM_CSV,
  IMPORT_QUESTIONS_IMAGES,
  FETCH_SCREENS,
} from "@/store/actions.type";
import {SET_QUESTIONS} from "@/store/mutations.type";
import _ from "lodash";
import {mapGetters} from "vuex";
import changeOrder from "@/helpers/changeOrder";
import Draggable from 'vuedraggable';
import VQuestion from "@/components/SurveyEdit/Design/VQuestion";
import VQuestionIcon from "@/components/VQuestionIcon";
import TheEmptyState from "@/components/Question/TheEmptyState";
import QuestionComment from "./Questions/QuestionComment";
import WelcomeScreen from "./Questions/WelcomeScreen";
import ThankyouScreen from "./Questions/ThankyouScreen";
import VIcon from "../../VIcon";
import VButton from "../../VButton";
import VCsvImport from "../../ImportQuestions/VCsvImport";
import VImagesImport from "../../ImportQuestions/VImagesImport";
import {CREATE_SCREEN, DELETE_SCREEN, DUPLICATE_SCREEN} from "../../../store/actions.type";

const tabs = {import_from_csv: 'Import from CSV', import_images: 'Images to questions'};

export default {
  name: "SurveyDesignQuestions",
  components: {
    ThankyouScreen,
    WelcomeScreen,
    QuestionComment,
    Draggable,
    VQuestion,
    VQuestionIcon,
    TheEmptyState,
    VIcon,
    VButton,
    VCsvImport,
    VImagesImport,
  },
  created() {
    this.updateOrderQuestions = _.debounce(this.updateOrderQuestions, process.env.VUE_APP_NEXT_REQUEST_DELAY_MS);
  },
  async mounted() {
    await this.$store.dispatch(`survey/${FETCH_QUESTIONS}`, {survey_id: this.$route.params.id});

    this.isLoadingScreens = true;
    await this.$store.dispatch(`survey/${FETCH_SCREENS}`, {survey_id: this.$route.params.id});
    this.isLoadingScreens = false;
  },
  data() {
    return {
      drag: false,
      modalImportQuestions: {
        title: 'Import questions',
        visible: false,
        closable: false,
        maskClosable: false,
        width: 800,
      },
      importedQuestionsFromCsv: [],
      importedImages: [],
      tabs: tabs,
      activeTab: tabs.import_from_csv,
      isImporting: false,
      isCreatingQuestion: false,
      isLoadingScreens: false,
      isCreatingWelcome: false,
      isCreatingThankyou: false,
      isDuplicatingThankyou: false,
      isDeletingThankyou: false,
      isDeletingWelcome: false,
    }
  },
  computed: {
    ...mapGetters('survey', ['survey', 'welcomeScreen', 'thankyouScreens', 'isLoadingChild', 'questions_count', 'questions_show_count']),
    questions: {
      get: function () {
        return this.$store.getters["survey/questions"];
      },
      set(questions) {
        this.$store.commit(`survey/${SET_QUESTIONS}`, {rows: questions, count: this.questions_count})
      }
    },
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        handle: ".drag-handle"
      }
    },
    canImport: function () {
      switch (this.activeTab) {
        case this.tabs.import_from_csv:
          return this.importedQuestionsFromCsv && this.importedQuestionsFromCsv.length > 0
        case  this.tabs.import_images:
          return this.importedImages && this.importedImages.length > 0
        default:
          return false
      }
    }
  },
  methods: {
    onClickSort(e) {
      let questions = this.questions;
      new Promise(function (resolve) {
        resolve(changeOrder(questions, e.targetIndex, e.relatedIndex));
      }).then(() => {
        this.updateOrderQuestions();
      });
    },
    onDragSortStart() {
      this.drag = true;
    },
    onDragSortEnd() {
      this.drag = false;
      this.updateOrderQuestions();
    },
    createQuestion(position) {
      this.isCreatingQuestion = true;

      let pos;
      if (position > 0) {
        pos = position;
      } else if (this.questions_count > 0) {
        let maxPosition = _.maxBy(this.questions, 'position').position;
        pos = maxPosition + 1;
      } else {
        pos = 1;
      }

      this.$store.dispatch(`survey/${QUESTION_CREATE}`, {
        survey_id: this.$route.params.id,
        payload: {
          position: pos,
        }
      })
          .then((newQuestion) => {
            this.scrollToQuestion(newQuestion._id);
            this.$posthog.capture('Question Created', {
              surveyId: this.$route.params.id,
              questionsCount: this.questions_count + 1,
            });
          })
          .finally(() => {
            this.isCreatingQuestion = false;
          });
    },
    updateOrderQuestions() {
      let questions = this.questions.map(function (question, index) {
        return {...question, position: index}
      });
      this.$store.dispatch(`survey/${QUESTIONS_UPDATE_POSITION}`, {
        survey_id: this.$route.params.id,
        questions,
      });
    },
    onImport() {
      const formData = new FormData();

      switch (this.activeTab) {
        case this.tabs.import_from_csv:
          this.isImporting = true;

          this.$store.dispatch(`survey/${IMPORT_QUESTIONS_FROM_CSV}`, {
            survey_id: this.$route.params.id,
            questions: _.map(this.importedQuestionsFromCsv, 'question'), // Use this to send a question title only
          })
              .then(() => {
                this.onCloseModal()
                this.onSuccessImport()

                this.$posthog.capture('Imported questions from CSV', {
                  surveyId: this.$route.params.id,
                  questionsCount: this.importedQuestionsFromCsv.length,
                });
              })
              .catch(() => {
                this.onFailImport()
              })
              .finally(() => {
                this.isImporting = false
              })
          break;
        case this.tabs.import_images:
          this.isImporting = true;

          formData.append('upload_url', process.env.VUE_APP_UPLOAD_URL);
          formData.append('survey_id', this.$route.params.id);

          this.importedImages.forEach(file => {
            formData.append('files[]', file);
          });

          this.$store.dispatch(`survey/${IMPORT_QUESTIONS_IMAGES}`, formData)
              .then(() => {
                this.onCloseModal()
                this.onSuccessImport()

                this.$posthog.capture('Imported questions from Images', {
                  surveyId: this.$route.params.id,
                  questionsCount: this.importedImages.length,
                });
              })
              .catch(() => {
                this.onFailImport()
              })
              .finally(() => {
                this.isImporting = false
              })

          break;
      }
    },
    onOpenImportModal(tabKey) {
      this.modalImportQuestions.visible = true
      this.activeTab = tabKey
    },
    onCloseModal() {
      this.modalImportQuestions.visible = false
      if (!this.isImporting) {
        this.importedQuestionsFromCsv = this.importedImages = []
      }
    },
    onSuccessImport() {
      this.$message.success("Import is finished successfully 🎉")
    },
    onFailImport() {
      this.$message.error("Import aborted. Please try again!")
    },
    onCreateWelcome() {
      this.isCreatingWelcome = true;
      this.$store.dispatch(`survey/${CREATE_SCREEN}`, {survey_id: this.$route.params.id, type: "welcome"})
          .finally(() => {
            this.isCreatingWelcome = false;
          });
    },
    onCreateThankyouScreen() {
      this.isCreatingThankyou = true;
      this.$store.dispatch(`survey/${CREATE_SCREEN}`, {survey_id: this.$route.params.id, type: "thankyou"})
          .finally(() => {
            this.isCreatingThankyou = false;
          });
    },
    onDeleteScreen({screenId, type}) {
      if (type === 'welcomeScreen') {
        this.isDeletingWelcome = true;
      } else {
        this.isDeletingThankyou = true;
      }

      this.$store.dispatch(`survey/${DELETE_SCREEN}`, {survey_id: this.$route.params.id, screenId})
          .finally(() => {
            if (type === 'welcomeScreen') {
              this.isDeletingWelcome = false;
            } else {
              this.isDeletingThankyou = false;
            }
          })

    },
    onDuplicateScreen(screenId) {
      this.isDuplicatingThankyou = true;
      this.$store.dispatch(`survey/${DUPLICATE_SCREEN}`, {
        survey_id: this.$route.params.id,
        screenId
      })
          .then((newScreen) => {
            // scroll to the new screen
            this.$nextTick(() => {
              if (!newScreen) return;
              const el = this.$refs[newScreen._id]?.[0];
              el.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"});
            });
          })
          .finally(() => {
            this.isDuplicatingThankyou = false;
          });
    },
    scrollToQuestion(questionId) {
      this.$nextTick(() => {
        const questionComponent = this.$refs[`question-${questionId}`]?.[0];
        if (questionComponent) {
          questionComponent.$el.scrollIntoView({
            behavior: "smooth",
            block: "center"
          });
        }
      });
    }
  }
}
</script>

<style lang="less">
.question_type_icon__wrapper {
  height: auto;
  width: 112px;
}

.flip-list-move {
  transition: transform .3s;
}
</style>
